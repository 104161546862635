import './../../styles/styles.css';
import { useUser, useTransaction, useMeeting } from "../../store/hooks";
import moment from 'moment/moment';

const TimeLine = (props: any) => {
  const { item, index }  = props;
  const { user } = useUser();
  const { selectedMeetingInfo, selectedChannelIndex, channelUnread, sendMessage, endQA } = useMeeting();
  const { selectedTransactionId, transactions, changeContract } = useTransaction();
  
  const differceFromToday = (str: any) => {
    let date1 = new Date().setHours(0, 0, 0, 0);
    let date2 = new Date(str).setHours(0, 0, 0, 0);

    return (date1 - date2) / (24 * 60 * 60 * 1000);
  }

  return (
   <>
        {(index === 0 || (index > 0 && (differceFromToday(item.datetime? item.datetime: item.created_at) !== differceFromToday(selectedMeetingInfo.channels[selectedChannelIndex].messages[index - 1].datetime? selectedMeetingInfo.channels[selectedChannelIndex].messages[index - 1].datetime: selectedMeetingInfo.channels[selectedChannelIndex].messages[index - 1].created_at)))) ? (
        <div className="text-center my-4">
            <span className="text-xs font-medium text-dp-gray-very-dark">
            {differceFromToday(item.datetime? item.datetime: item.created_at) === 0 ? "Today" :
                ((differceFromToday(item.datetime? item.datetime: item.created_at) === 1) ? "Yesterday" :
                ((differceFromToday(item.datetime? item.datetime: item.created_at) < 4) ? moment(new Date(item.datetime? item.datetime: item.created_at)).format('dddd')
                    : moment(new Date(item.datetime? item.datetime: item.created_at)).format('dddd, MMMM D, YYYY')))
            }
            </span>
        </div>
        ) : null}
        {channelUnread !== 0 && (selectedMeetingInfo.channels[selectedChannelIndex].messages.length - index) === channelUnread ? (
        <div className="w-full flex items-center my-4" style={{ paddingLeft: "50px" }}>
            <div className="w-full bg-blue-500" style={{ height: "2px" }}></div>
            <div className="px-3 text-sm text-blue-500 whitespace-nowrap">Unread Messages</div>
            <div className="w-full bg-blue-500" style={{ height: "2px" }}></div>
        </div>
        ) : null}
   </>
  );
};

export default TimeLine;