import { useState, useEffect } from "react";
import { useConsts } from '../../store/hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const TagsInput = (props: any) => {
  const [tags, setTags] = useState<any[]>(props.selectedTags ? props.selectedTags : []);
  const [showtags, setShowtags] = useState<any[]>([]);
  const { hashtags, getHashTags } = useConsts();

  useEffect(() => {
    getHashTags();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTags(props.selectedTags);
  }, [props.selectedTags])
  useEffect(() =>{
    console.log(tags);
    let _showTags = [];
    let tag_labels = tags.map((option: any) => option.hashtag_name);
    hashtags.map((_hashtags)=>{
      if (!(tag_labels.indexOf(_hashtags.hashtag_name) > -1)) {
        _showTags.push(_hashtags);
      }
    })
    setShowtags(_showTags);
  },[hashtags, tags])

  const addTags = (event: any, value: any) => {
    if (value) {
      var tag = value.hashtag_name.trim();
      let tag_labels = tags.map((option: any) => option.hashtag_name);

      if (!(tag_labels.indexOf(tag) > -1)) {
        let tagsTmp = [...tags];
        tagsTmp.push(value);
        setTags(tagsTmp);
        console.log(tags);
        props.setSelectedTags(tagsTmp);
      }
    }
  };

  const removeTags = (index: any) => {
    let tag_labels = tags.map((option: any) => option.hashtag_name);
    setTags([...tags.filter((tag: any) => tag_labels.indexOf(tag.hashtag_name.trim()) !== index)]);
    props.setSelectedTags([...tags.filter((tag: any) => tag_labels.indexOf(tag.hashtag_name.trim()) !== index)]);
  };

  return (
    <div className="tags-input">
      {props.tagsInputType === 'view' ? '' :
        <Autocomplete
          id="filter-demo"
          options={showtags}
          getOptionLabel={(option) => {
            return option.hashtag_name;
          }}
          renderOption={(props) => {
            return <li {...props}>{props.hashtag_name}</li>
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            console.log(filtered);
            return filtered;
          }}
          renderInput={(params: any) =>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                placeholder: "Input Tags",
                style: { color: "rgb(74, 85, 104)" },
              }}
              variant="outlined" />}
          onChange={addTags}
        />
      }

      <div className="mt-5">
        {tags.map((tag, index) => (
          <button key={index} className="chip">
            <span>{tag.hashtag_name}</span>
            {props.tagsInputType === 'view' ? '' : (<span onClick={e => removeTags(index)}>
              <svg className="pl-2 h-3 w-3 box-content" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>)}
          </button>
        ))}
      </div>

    </div>
  );
};

export default TagsInput;