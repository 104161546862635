import React, { FunctionComponent, useEffect } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';

import HomePage from '../unauth-pages/home-page'
import ProfilePage from './profile-page';
import SettingPage from './setting-page';
import MeetingPage from './meeting-page'
import TransactionPage from './transaction-page'
import MeetingsPage from './meetings-page'
import TicketsPage from './tickets-page'
import TagsPage from './tags-page'
import SelectMeetingPage from '../unauth-pages/select-meeting-page'
import PostJobPage from '../unauth-pages/post-job-page'
import MatchFreelancersPage from '../unauth-pages/match-freelancers-page'
import TicketViewPage from './tickets-page/ticketView'
import SummaryPage from './summary-page';
import { useUser, useMeeting, useTransaction } from '../../store/hooks'
import Pusher from 'pusher-js'
import Header from '../../layouts/header'
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import { LOCAL_STORAGE_KEY } from "../../consts";
const { JWT_TOKEN, USER_DATA } = LOCAL_STORAGE_KEY;

const AuthPages: FunctionComponent = () => {
  const history = useHistory();
  const { user, setPusherChannel, setUnreadMeeting, setUnreadTransaction, updateStripeAccountComplete } = useUser();
  const { loadNewMessage, loadNewMeeting } = useMeeting();
  const { loadNewMessageTr, handleContractStatusChange, addTransaction, removeTimeTractTr } = useTransaction();  

  useEffect(() => {
    (async ()=>{
      const token = await localStorage.getItem(JWT_TOKEN);
      console.log('token',token);
      if(token === '' || token === null){
        history.push("/");
      }
    })();
  })

  useEffect(() => {
    if (user.user.user_id !== '') {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: process.env.REACT_APP_INSTANCE_ID,
       })
      beamsClient.start()
      .then(() => beamsClient.addDeviceInterest(user.user.user_id + "_web"))
      .then(() => {
    
       });
      let options = {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
      };
      let key: any = process.env.REACT_APP_PUSHER_APP_KEY;
      var pusher = new Pusher(key, options);
      var channel = pusher.subscribe('chat-new-channel-' + user.user.user_id);
      setPusherChannel(channel);

      channel.bind('available', function(data) {
        console.log(">>>>>>>>>>>>>>> state_change: ", data)
      });

      channel.bind('MessageSent', async function (message: any) {
        console.log("Pusher_obj => ", message);
        if (message.type === 'contract') {
          if (message.create_type == 1) {
            addTransaction(message.data);
          } else {
            if (
              message.data.chat_type == 'contract_sent' ||
              message.data.chat_type == 'contract_accepted' ||
              message.data.chat_type == 'contract_declined' ||
              message.data.chat_type == 'contract_canceled'
            ) {
              // let unread = user.unreadMeeting + 1;
              // setUnreadMeeting(unread);
              if(message.data.create_type == 1) {
                handleContractStatusChange(message);
              } else {
                loadNewMessage(message.data);
              }
            } else {
              // let unread = user.unreadTransaction + 1;
              // setUnreadTransaction(unread);
              handleContractStatusChange(message);
            }
          }
        }
        else if (message.type === 'message' || message.type === 'timetrack' || message.type === "review") {
          let unread = user.user.unreadTransaction + 1;
          setUnreadTransaction(unread);
          if(message.type == 'timetrack' && message.status == 'removed') {
            removeTimeTractTr(message.data);
          } else {
            loadNewMessageTr({ ...message.data, chat_type: message.type });
          }
        }
        else if (message.type === 'm_chat') {
          console.log('here', message);
          let unread = user.user.unreadMeeting + 1;
          loadNewMessage(message.data);
        }
        else if (message.type === 'invoice_sent' || message.type === 'invoice_paid') {
          let unread = user.unreadTransaction + 1;
          setUnreadTransaction(unread);
          loadNewMessageTr({ ...message.data, chat_type: message.type });
        }
        else if (message.type === 'new_job') {
          loadNewMeeting(message.data);
        }
        else if (message.type == 'stripe_account_complete') {
          updateStripeAccountComplete(message.data)
        }
      });
    }
  }, [user.user.user_id]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Header></Header>
      <div className='h-screen relative flex flex-col bg-dp-gray-very-light p-2' style={{ height: "calc( 100vh - 64px )" }}>
        <Switch>
          <Route exact path="/" component={MeetingsPage} />
          <Route exact path="/client/post-job" component={PostJobPage} />
          <Route exact path="/client/match-freelancers" component={MatchFreelancersPage} />
          <Route exact path="/profile/:id" component={ProfilePage} />
          <Route exact path="/setting" component={SettingPage} />
          <Route exact path="/tickets" component={TicketsPage} />
          <Route exact path="/ticket/:id" component={TicketViewPage} />
          <Route exact path="/tags" component={TagsPage} />
          <Route exact path="/transaction" component={TransactionPage} />
          <Route exact path="/client/select-meeting" component={SelectMeetingPage} />
          <Route exact path="/meetings" component={MeetingsPage} />
          <Route exact path="/summary" component={SummaryPage} />
          <Route exact path="/meeting/:id" component={MeetingPage} />         
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default AuthPages;
