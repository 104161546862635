import '../../../styles/tailwind.css';

import Header from '../../../layouts/header'
import { useEffect, useState } from 'react';
function HomePage() {

  const [message, setMessage] = useState('');
  const sendMessage = () => {
    setTimeout(()=>{
      setMessage('Thank you. We received your message. We will reply via email as soon as possible!');
    }, 1000);    
  }
  return (
    <div>
      <Header></Header>

      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Support</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Send us a Message if you need our Help.</p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            {message != '' &&
              <p className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center text-bold">{message}</p>
            }
            {message == '' &&
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label className="leading-7 text-sm text-gray-600">Name</label>
                    <input type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" disabled={message != ''} />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label className="leading-7 text-sm text-gray-600">Email</label>
                    <input type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" disabled={message != ''} />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label className="leading-7 text-sm text-gray-600">Message</label>
                    <textarea id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" disabled={message != ''}></textarea>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg" onClick={() => sendMessage()}>Submit</button>

                </div>
                <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                  <a className="text-indigo-500">support@asiangurus.com</a>
                  <span className="inline-flex">
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </section>

    </div>
  );
}

export default HomePage;
