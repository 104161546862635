import { useHistory } from "react-router-dom";
import { usePostJob } from "../store/hooks";

function Footer() {
    const history = useHistory();
    const { initJobInfo } = usePostJob();

    const onLoginButton = (e: any) => {
        e.preventDefault();
        history.push("/auth/login");
    }

    const onHireButton = (e: any) => {
        e.preventDefault();
        initJobInfo();      
        history.push('/client/post-job');        
    }

    const onFreelancerRegisterButton = (e: any) => {
        e.preventDefault();
        history.push("/freelancer/signup");
    }

    return (
        <div>
            <footer className="bg-white dark:bg-gray-900 mt-4">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="md:flex md:justify-between">
                        <div className="mb-6 md:mb-0">
                            <a href="https://asiangurus.com" className="flex items-center">
                                <img src="/logo.png" className="h-8 mr-3" alt="FlowBite Logo" />
                                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">AsianGurus</span>
                            </a>
                        </div>
                        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
                                <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                    <li className="mb-4">
                                        <a href="/privacy" className="hover:underline">Privacy</a>
                                    </li>
                                    <li>
                                        <a href="/teams" className="hover:underline">Develop Team</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Links</h2>
                                <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                    <li className="mb-4">
                                        <a href="void(0)" onClick={onLoginButton} className="hover:underline ">Login</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="void(0)" onClick={onFreelancerRegisterButton} className="hover:underline">Apply as a freelancer</a>
                                    </li>
                                    <li>
                                        <a href="void(0)" onClick={onHireButton} className="hover:underline">Apply as a client</a>
                                    </li>
                                </ul>
                            </div>                          
                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 Asiangurus.com. All Rights Reserved.
                        </span>                        
                    </div>
                </div>
            </footer>            
        </div>
    );
}

export default Footer;
