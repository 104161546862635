export const TICKETS = {
  GET_TICKETS: 'GET_TICKETS',
  GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
  GET_TICKETS_FAILED: 'GET_TICKETS_FAILED',
  GET_TICKETINFOBYID: 'GET_TICKETINFOBYID',
  GET_TICKETINFOBYID_SUCCESS: 'GET_TICKETINFOBYID_SUCCESS',
  GET_TICKETINFOBYID_FAILED: 'GET_TICKETINFOBYID_FAILED',
  UPDATE_SELECTEDTICKETINFO: 'UPDATE_SELECTEDTICKETINFO',
  UPDATE_SELECTEDTICKETINFO_SUCCESS: 'UPDATE_SELECTEDTICKETINFO_SUCCESS',
  UPDATE_SELECTEDTICKETINFO_FAILED: 'UPDATE_SELECTEDTICKETINFO_FAILED',
  ADD_TICKET: 'ADD_TICKET',
  ADD_TICKET_SUCCESS: 'ADD_TICKET_SUCCESS',
  ADD_TICKET_FAILED: 'ADD_TICKET_FAILED',
}