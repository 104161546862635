import { useEffect, useState } from "react";
import { AuthService } from "../../../services";
import { useHistory } from "react-router-dom";
import qs from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import { useUser, usePostJob } from "../../../store/hooks";


function CallbackPage(props: any) {
  const [showAlert, setShowAlert] = useState(false);
  const [errormsg, setErrorMsg] = useState('');
  const [state, setState] = useState(0);
  const history = useHistory();
  const { userLoginWithData, userSignupWithData } = useUser();
  const { addJobInfo } = usePostJob();
  // const query_value = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (props.location.search) {
      callback(props.location.search)
    }
  }, [props.location.search])

  const callback = async (query) => {
    try {
      let result = await AuthService.googleCallback(query)
      if(result.data.state == 0) {
        userSignupWithData(result);
        history.push("/profile/me");
      } else if (result.data.state == 1) {
        userSignupWithData(result);
        await addJobInfo();
        history.push("/meetings");
      } else {
        userLoginWithData(result);
        history.push("/home");
      }
    } catch ({ response, message }) {
      setErrorMsg(response.data.error);
      setState(response.data.state);
      setShowAlert(true);
    }
  }

  const confirmHandle = async () => {
    setShowAlert(false);
    if(state == 0) {
      history.push('/freelancer/signup');
    } else if (state == 1) {
      history.push('/client/select-meeting');
    } else {
      history.push('/auth/login');
    }
  }

  return (
    <>
      <SweetAlert
        danger
        title=""
        show={showAlert}
        onConfirm={confirmHandle}
      >
        {errormsg}
      </SweetAlert>
    </>
  );
}

export default CallbackPage;
