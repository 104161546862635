import { apiUrls } from '../consts';
import { axiosService } from '.';

export const MeetingService = (function () {

  const getMeetingInfoById = async (id: any) => {
    return axiosService.get(apiUrls.MEETINGS + '/' + id);
  };

  const getMeetings = async () => {
    return axiosService.get(apiUrls.MEETINGS);
  }

  const sendMessage = async (payload: any) => {
    return axiosService.post(apiUrls.MEETINGS, payload);
  }

  const setUnreadasRead = async (id: any) => {
    return axiosService.post(apiUrls.SET_UNREAD_AS_READ_BYID, { id: id });
  }

  const endQA = async (room_id: any, channel_id: any) => {
    return axiosService.post(`${apiUrls.MEETINGS}/${room_id}/${channel_id}/update`, { is_free: true });
  }

  const updateAvailableStatus = async (payload: any) => {
    return axiosService.post(apiUrls.UPDATE_AVAILABLE_STATUS, payload);
  }

  return {
    getMeetings,
    getMeetingInfoById,
    sendMessage,
    setUnreadasRead,
    endQA,
    updateAvailableStatus
  };
})();
