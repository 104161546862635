import { apiUrls } from '../consts';
import { axiosService } from '.';

export const TransactionService = (function () {
  const getTransactions = async () => {
    return axiosService.get(apiUrls.TRANSACTIONS);
  }

  const getSummary = async (startDate, endDate) => {
    return axiosService.get(apiUrls.SUMMARY+'?startDate='+startDate+'&endDate='+endDate);
  }

  const addContract = async (payload: any) => {
    return axiosService.post(apiUrls.MAKE_CONTRACT, payload);
  }

  const changeContractLimit = async (payload: any) => {
    return axiosService.post(apiUrls.CHANGE_CONTRACT_LIMIT, payload);
  }

  const sendMessage = async (payload: any) => {
    return axiosService.post(apiUrls.TRANSACTIONS, payload);
  }

  const changeContract = async (payload: any) => {
    return axiosService.post(apiUrls.CHANGE_CONTRACT, payload);
  }

  const removeTimeTrack = async (payload: any) => {
    return axiosService.post(apiUrls.REMOVE_TIMETRACK, payload);
  }

  const setUnreadasReadTr = async (id: any) => {
    return axiosService.post(apiUrls.SET_UNREAD_AS_READ_TR, { id: id });
  }

  return {
    sendMessage,
    setUnreadasReadTr,
    addContract,
    changeContract,
    getTransactions,
    changeContractLimit,
    removeTimeTrack,
    getSummary
  };
})();
