import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { useMeeting, useUser } from "../../store/hooks";
import ContractModal from '../../components/Contract-Modal'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const countdownValue = 120;

export default function MeetingTitle() {
	const { selectedMeetingInfo, MeetingSelected, selectedChannelIndex, endQA, leaveMeeting, onKick, endMeeting } = useMeeting();
	const history = useHistory();
	const { user } = useUser();
	const messageEl = useRef<HTMLDivElement>(null);
	const onViewProfile = () => {
		if (user.user.user_role === "client")
			window.open('/profile/' + selectedMeetingInfo.channels[selectedChannelIndex].fre_id);
		else if (user.user.user_role === "freelancer")
			window.open('/profile/' + selectedMeetingInfo.channels[selectedChannelIndex].clt_id);

	}
	const countdownRef = useRef(countdownValue);
	const countdownTimer = useRef<NodeJS.Timeout>();
	const lastLengthMessages = useRef(0)
	const [countdown, setCountdown] = useState(countdownRef.current)

	const [showModal, setShowModal] = useState(false);
	const onSendOffer = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	}

	useEffect(() => {
		if (selectedChannelIndex === -1)
			return;

		const messages = selectedMeetingInfo.channels[selectedChannelIndex].messages;
		const newLengthMessages = messages.length;

		if (newLengthMessages === 0)
			return;

		if (user.user.user_role === "freelancer" && messages[newLengthMessages - 1].user_id === user.user.user_id)
			return;

		if (lastLengthMessages.current !== 0 && newLengthMessages > lastLengthMessages.current) {
			if (countdownTimer.current)
				clearTimeout(countdownTimer.current);

			countdownRef.current = countdownValue;
			setCountdown(countdownRef.current);
			countdownHandler();
		}

		lastLengthMessages.current = newLengthMessages;
	}, [selectedMeetingInfo, selectedChannelIndex])

	const countdownHandler = () => {
		countdownTimer.current = setTimeout(() => {
			setCountdown(--countdownRef.current)

			if (countdownRef.current > 0) {
				countdownHandler();
			}
		}, 1000);
	}

	const onEndInterview = () => {
		confirmAlert({
			title: 'Are you sure?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => endMeeting()
				},
				{
					label: 'No',
					onClick: () => { return false; }
				}
			]
		});
	}

	const onKickFree = () => {
		confirmAlert({
			title: 'Are you sure?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => onKick()
				},
				{
					label: 'No',
					onClick: () => { return false; }
				}
			]
		});
	}

	const onLeaveMeeting = () => {
		confirmAlert({
			title: 'Are you sure?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => leaveMeeting()
				},
				{
					label: 'No',
					onClick: () => { return false; }
				}
			]
		});
	}



	return (
		<>
			{showModal ? <ContractModal selectedMeetingInfo={selectedMeetingInfo} setShowModal={setShowModal}></ContractModal> : null}
			<div className="flex items-center justify-between h-16 px-3.5 border-b border-dp-gray">
				<div className="flex items-center">
					<div className="flex flex-col">
						<p
							className="text-dp-blue-very-dark font-semibold leading-tight block hover:underline focus:outline-none text-left"
						>
							{selectedMeetingInfo?.title}
						</p>
						<span className="text-dp-gray-very-dark text-sm block">
							{selectedChannelIndex !== -1 ? (<>
								{user.user.user_role === 'client' ?
									(selectedChannelIndex === 0 ?
										(<div>
											<b>Group Chat</b>
										</div>)
										:
										(<div className='flex flex-1'>
											<Avatar
												style={{ width: "1.5rem", height: "1.5rem" }}
												src={process.env.REACT_APP_BASE_URL + selectedMeetingInfo.channels[selectedChannelIndex].avatar}
												alt={selectedMeetingInfo.channels[selectedChannelIndex].full_name}>
												{selectedMeetingInfo.channels[selectedChannelIndex].full_name.charAt(0).toUpperCase()}
											</Avatar>&nbsp;
											{selectedMeetingInfo.channels[selectedChannelIndex].full_name}
										</div>)
									)
									:
									(selectedChannelIndex === 0 ?
										(<div>
											<b>Group Chat</b>
										</div>)
										:
										(<div className='flex flex-1'>
											<Avatar
												style={{ width: "1rem", height: "1rem" }}
												src={process.env.REACT_APP_BASE_URL + selectedMeetingInfo.channels[selectedChannelIndex].clt_avatar}
											>
											</Avatar>&nbsp;
											{selectedMeetingInfo.channels[selectedChannelIndex].full_name}
										</div>)
									)
								}
							</>) : ""}
						</span>
					</div>
				</div>
				<div className="flex space-x-1">
					{selectedChannelIndex !== -1 ? (<>
						{user.user.user_role === 'client' ?
							(selectedChannelIndex === 0 ? (<div className="flex pl-10 pr-10 justify-between items-center">
								<button onClick={onEndInterview} className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs">
									End Interview
								</button>
							</div>) : (
								<div className="flex pl-10 pr-10 justify-between items-center">
									<button onClick={onViewProfile} className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs">
										View Profile
									</button>
									&nbsp;
									{selectedMeetingInfo.channels[selectedChannelIndex].has_contract == false &&
										<>
											<button
												className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs"
												onClick={(e: any) => onSendOffer(e)}
											>
												Send Offer
											</button>
											&nbsp;
											<button
												className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs"
												onClick={(e: any) => onKickFree()}
											>
												Kick
											</button>
										</>
									}
								</div>
							)) : (<div className="flex pl-6 pr-10 justify-between items-center">
								<button onClick={onViewProfile} className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs">
									View Client Profile
								</button>
								&nbsp;
								{selectedMeetingInfo.channels[selectedChannelIndex].has_contract == false &&
									<button onClick={onLeaveMeeting} className="text-center rounded-lg p-2 bg-dp-blue-dark text-white hover:opacity-75 transition text-xs">
										Leave the meeting
									</button>
								}
							</div>)
						}
					</>) : ""}
				</div>
				{/* <div className="flex space-x-1">
					<CallButton
						onClick={callCustomer}
						available={
							rtcStatus.dialStatus !== EDialStatus.OUTBOUND_IN_CALL &&
							rtcStatus.dialStatus !== EDialStatus.OUTBOUND_RINGING &&
							rtcStatus.dialStatus !== EDialStatus.INBOUND_IN_CALL
						}
					/>
					{customer.textable && (
						<SMSButton
							onClick={() => setModalVisible(true)}
							available={
								rtcStatus.dialStatus !== EDialStatus.OUTBOUND_IN_CALL &&
								rtcStatus.dialStatus !== EDialStatus.OUTBOUND_RINGING &&
								rtcStatus.dialStatus !== EDialStatus.INBOUND_IN_CALL
							}
						/>
					)} */}
				{/*</div>*/}
			</div>
		</>
	);
}
