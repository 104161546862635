export const USER = {
  USER_INIT: 'USER_INIT',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILED: 'USER_FAILED',
  USER_RESET: 'USER_RESET',
  SET_USER_DATA: 'SET_USER_DATA',
  UPDATE_STRIPE_ACCOUNT_COMPLETE: 'UPDATE_STRIPE_ACCOUNT_COMPLETE',
  SET_PROFILE_DATA: 'SET_PROFILE_DATA',
  GET_PROFILE_DATA: "GET_PROFILE_DATA",
  GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
  GET_PROFILE_DATA_FAILED: "GET_PROFILE_DATA_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SET_PUSHER_CHANNEL: 'SET_PUSHER_CHANNEL',
  SET_UNREAD_MEETING: 'SET_UNREAD_MEETING',
  SET_UNREAD_TRANSACTION: 'SET_UNREAD_TRANSACTION',
  UPDATE_DRAFT_JOB: 'UPDATE_DRAFT_JOB',
}