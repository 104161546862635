import { useTransaction } from "../../store/hooks";
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { convertUTCToLocal  } from '../../utils';

const TransactionListItem = (props: any) => {
  const { setSelectedTransactionId, selectedTransactionId } = useTransaction();

  return (
    <button
			onClick={() => setSelectedTransactionId(props.index)}
			className={clsx('transition w-full px-3 py-2 focus:outline-none rounded-md flex-shrink-0', selectedTransactionId === props.index ? 'bg-dp-blue-light': 'bg-white')}
		>
			<div className="flex justify-between items-center w-full mb-1">
				<span className={`text-dp-blue-very-dark font-semibold text-sm truncate`}>{props.contact.contract_title}</span>
        <span className={`text-sm truncate`}>{convertUTCToLocal(props.contact.last_time).format('Do MMM h:mm a')}</span>
        {props.contact.unread !== 0 && (
          <div
            className='ml-4 flex items-center justify-center w-5 h-5 rounded-full text-xs text-center bg-blue-500 text-white'
          >
            {props.contact.unread}
          </div>
        )}
      </div>
      <div className="flex justify-between items-center w-full mb-1">
        <div className="flex items-center">
          <Avatar src={process.env.REACT_APP_BASE_URL + props.contact.avatar} alt={props.contact.full_name} style={{width: 24, height: 24, fontSize: '1rem'}}>
            {props.contact.first_name === "" ? null : (props.contact.first_name.charAt(0).toUpperCase() + props.contact.last_name.charAt(0).toUpperCase())}
          </Avatar>
          <span className={`text-sm truncate ml-2`}>Weekly Limit: {props.contact.contract_max_hrs}hrs</span>
        </div>
        <div>
          <span className={`text-sm truncate`}>${props.contact.contract_hourly_rate}/h</span>
        </div>
        
      </div>
		</button>
  );
}
export default TransactionListItem;
