export const apiUrls = {
  /**
   * Accounts management API urls
   */
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  loginGoogle: '/auth/google_sign',
  handleGoogleCallback: '/auth/google_callback',

  /**
   * Client signup
   */
  GET_CONFIRMCODE: '/user/get_confirmcode',

  /***
   * Update User Info Apis
   */
  GET_USERDATA: '/user/get_userdata',
  GET_PROFILE_BYID: '/user/get_profiledata',
  UPDATE_USERDATA: '/user/update_userdata',
  CHANGE_PASSWORD: '/auth/change_password',
  FORGOT_PASSWORD: '/auth/forgot_password',
  RESET_PASSWORD: '/auth/reset_password',
  DELETE_ACCOUNT: '/auth/removeAccount',
  GET_AVAILABE_AVATARS: '/get_avatars',

  GET_STRIPE_CONNECT_URL: '/user/stripe_connect_url',
  
  /***
   * Portfolio Urls
   */
  PORTFOLIOS: '/portfolios',

  /**
   * Get Initial Consts
   */
  HASHTAGS: '/hashtags',

  /**
   * Tickets
   */
  TICKETS: '/tickets',

  /**
   * Jobs
   */
  JOBS: '/jobs',
  SAVE_DRAFT: '/jobs/draft',
  GET_TOP_FREELANCERS: '/get_top_freelancers',

  /**
   * Meetings
   */
  MEETINGS: '/meetings',
  SET_UNREAD_AS_READ_BYID: '/meetings/set_unread_as_read_byid',
  UPDATE_AVAILABLE_STATUS: '/meetings/update_available_status',

  /**
   * Transaction
   */
  TRANSACTIONS: '/transactions',
  SET_UNREAD_AS_READ_TR: '/transactions/set_unread_as_read_byid',
  MAKE_CONTRACT: '/transactions/make_contract',
  CHANGE_CONTRACT_LIMIT: '/transactions/change_contract_attr',
  CHANGE_CONTRACT: '/transactions/change_contract',
  REMOVE_TIMETRACK: '/transactions/remove_timetrack',
  SUMMARY: '/transactions/summary',
};
