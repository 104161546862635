import { ChangeEvent, useState } from 'react';

interface SidebarTitleProps {
	title: string;
	hasSearch?: boolean;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onClose?: () => void;
	placeholder?: string;
}

export default function SidebarTitle({ title, value, onChange, hasSearch, placeholder, onClose }: SidebarTitleProps) {
	const [showSearch, setShowSearch] = useState(false);

	const handleClose = () => {
		setShowSearch(false);
		if (onClose) {
			onClose();
		}
	};

	if (showSearch) {
		return (
			<div className="relative flex items-center pl-3 pr-1 h-10 bg-white rounded-md animation-duration-250 border-2 border-transparent focus-within:border-dp-blue-dark transition">
				<svg className="h-4 w-4" viewBox="0 0 18.414 18.414">
					<g transform="translate(1 1)" fill="none" stroke="#959dab" strokeWidth="2">
						<circle cx="6.633" cy="6.633" r="6.633" />
						<path strokeLinecap="round" d="M11.534 11.534L16 16.001" />
					</g>
				</svg>
				<button
					onClick={handleClose}
					className={`absolute text-dp-blue-very-dark right-0 bg-white focus:outline-none z-10 transition duration-150`}
				>
					<svg className="h-8 w-8 text-transparent hover:text-dp-blue-very-dark transition" viewBox="0 0 32 32">
						<circle cx="16" cy="16" r="16" fill="currentColor" opacity=".12" />
						<g transform="translate(15 15)" fill="none" stroke="#081e42" strokeLinecap="round" strokeWidth="2">
							<path d="M-4-4l9.9999041 9.9999041" />
							<path d="M6-4l-9.9999041 9.9999041" />
						</g>
					</svg>
				</button>
				<div className="flex-1 ml-2 text-dp-blue-very-dark">
					<input
						autoFocus={true}
						type="text"
						className={`w-full border-0 focus:ring-0 px-0 focus:outline-none transition duration-150 text-sm`}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
					/>
				</div>
			</div>
		);
	}

	return (
		<button
			onClick={() => setShowSearch(true)}
			className="w-full bg-dp-gray-light h-10 rounded-md flex items-center justify-between pl-3 pr-1 relative animation-duration-250"
		>
			<h2 className="text-base font-semibold leading-relaxed">{title}</h2>
			{hasSearch && (
				<svg className="text-transparent hover:text-dp-blue-very-dark transition h-8 w-8" viewBox="0 0 32 32">
					<circle cx="16" cy="16" r="16" fill="currentColor" opacity=".12" />
					<g transform="translate(8 8)" fill="none" stroke="#656f82" strokeWidth="2">
						<circle cx="6.633" cy="6.633" r="6.633" />
						<path strokeLinecap="round" d="M11.534 11.534L16 16.001" />
					</g>
				</svg>
			)}
		</button>
	);
}
