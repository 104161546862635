import { useEffect, useMemo, useState } from 'react';
import { useMeeting, useUser } from "../../store/hooks";
import LoadingMeetingMembers from './LoadingMeetingMembers';
import MeetingMember from './MeetingMember';
import Separator from '../Common/Separator';

export default function TeamMembers() {
	const { selectedMeetingInfo, MeetingSelected, selectedChannelIndex } = useMeeting();
	const { user } = useUser();
	const [statusMenuEl, setStatusMenuEl] = useState(null);

	useEffect(() => {
		console.log("selectedMeetingInfo: ", selectedMeetingInfo)
	}, [selectedMeetingInfo])

	const handleStatusMenuClick = (event: any) => {
		event.preventDefault();
		setStatusMenuEl(event.currentTarget);
	};

	const handleStatusClose = (event: any) => {
		event.preventDefault();
		setStatusMenuEl(null);
	};

	const handleStatusSelect = (event: any, status: any) => {
		event.preventDefault();
		setStatusMenuEl(null);
	}

	return (
		<>
			{!MeetingSelected ? (
				// <LoadingMeetingMembers />
				<></>
			) : (
				<div className={`${!MeetingSelected ? 'opacity-50' : 'opacity-100'} transition flex flex-col flex-1 space-y-2`}>
					<div className="relative flex-1">
						<div className="absolute top-0 left-0 w-full h-full">
							<div className="flex flex-col max-h-full mb-2 space-y-2 overflow-y-auto">
								{selectedMeetingInfo?.channels?.map((channel: any, index: any) => (
									<MeetingMember
										key={index}
										id={index}
										full_name={user.user.user_role == 'client' ? channel?.full_name: channel.full_name == 'Group Chat' ? channel.full_name: 'Private Chat' }
										lastMessageTime={channel?.lastMessageTime}
										avatar={channel?.avatar}
										rate={channel?.rate}
										active={index == selectedChannelIndex}
										fake_name={selectedMeetingInfo.contacts ? selectedMeetingInfo.contacts[channel.fre_id]: null}
										unread={channel?.unread}
									/>
								))}
							</div>
							<Separator />
						</div>
					</div>
				</div>
			)}
		</>
	);
}
