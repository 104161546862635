import './../../styles/styles.css'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useUser, useTransaction, useMeeting } from "../../store/hooks";
import clsx from 'clsx';
import moment from 'moment/moment';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  messageRow: {
    '&.contact': {
      '& .bubble': {
        backgroundColor: "rgb(226,232,240)",
        color: "black",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: '1px',
        wordBreak: "break-all",
        width: "fit-content",
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopLeftRadius: 10
        }
      },
      '&.last-of-group': {
        marginBottom: "1.5rem",
        '& .bubble': {
          borderBottomLeftRadius: 10,
        }
      }
    },
    '&.me': {
      '& .avatar': {
        order: 2,
        margin: '0 0 0 16px'
      },
      '& .bubble': {
        marginLeft: 'auto',
        backgroundColor: "rgb(49,130,206)",
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: '1px',
        wordBreak: "break-all",
        width: "fit-content",
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopRightRadius: 10
        }
      },

      '&.last-of-group': {
        marginBottom: "1.5rem",
        '& .bubble': {
          borderBottomRightRadius: 10,
        }
      }
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 10,
      }
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 10,
      }
    }
  }
}));

const Message = (props: any) => {
  const { item, index } = props;
  const { user } = useUser();
  const { selectedMeetingInfo, selectedChannelIndex, channelUnread, sendMessage, endQA } = useMeeting();
  const { selectedTransactionId, transactions, changeContract } = useTransaction();
  const classes = useStyles(props);
  const isFirstMessageOfGroup = (item: any, i: any) => {
    if (i === 0)
      return true;

    if (selectedMeetingInfo.channels[selectedChannelIndex].messages[i - 1]) {
      if (selectedMeetingInfo.channels[selectedChannelIndex].messages[i - 1].user_id !== item.user_id)
        return true;
      else {
        const date1 = new Date(selectedMeetingInfo.channels[selectedChannelIndex].messages[i].datetime? selectedMeetingInfo.channels[selectedChannelIndex].messages[i].datetime: selectedMeetingInfo.channels[selectedChannelIndex].messages[i].created_at).getTime();
        const date2 = new Date(selectedMeetingInfo.channels[selectedChannelIndex].messages[i - 1].datetime? selectedMeetingInfo.channels[selectedChannelIndex].messages[i - 1].datetime: selectedMeetingInfo.channels[selectedChannelIndex].messages[i - 1].created_at).getTime();

        if (date1 - date2 > 180000)
          return true;
      }
    }
    return false;
  }

  const isLastMessageOfGroup = (item: any, i: any) => {
    if (i === selectedMeetingInfo.channels[selectedChannelIndex].messages.length - 1)
      return true;

    if (selectedMeetingInfo.channels[selectedChannelIndex].messages[i + 1]) {
      if (selectedMeetingInfo.channels[selectedChannelIndex].messages[i + 1].user_id !== item.user_id)
        return true;
      else {
        const date1 = new Date(selectedMeetingInfo.channels[selectedChannelIndex].messages[i + 1].datetime? selectedMeetingInfo.channels[selectedChannelIndex].messages[i + 1].datetime: selectedMeetingInfo.channels[selectedChannelIndex].messages[i + 1].created_at).getTime();
        const date2 = new Date(selectedMeetingInfo.channels[selectedChannelIndex].messages[i].datetime? selectedMeetingInfo.channels[selectedChannelIndex].messages[i].datetime: selectedMeetingInfo.channels[selectedChannelIndex].messages[i].created_at).getTime();

        if (date1 - date2 > 180000)
          return true;
      }
    }
  }

  const shouldShowContactAvatar = (item: any, i: any) => {
    if (item.user_id !== user.user.user_id)
      return true;
    return false;
  }

  return (
    <div className='chat-message'>
      <div
        className={clsx(
          'flex items-end',
          { 'justify-end': item.user_id === user.user.user_id },
          { 'first-of-group': isFirstMessageOfGroup(item, index) },
          { 'last-of-group': isLastMessageOfGroup(item, index) },
          index + 1 === selectedMeetingInfo.channels[selectedChannelIndex].messages.length && 'pb-2'
        )}
      >
        {shouldShowContactAvatar(item, index) ? (
          isFirstMessageOfGroup(item, index) ? (
            <>
              <div className='flex flex-col text-xs max-w-xs mx-2 order-2 items-start'>
                {isFirstMessageOfGroup(item, index) ?
                  (<p
                    className="time w-full text-xs ltr:left-0 rtl:right-0 bottom-0 whitespace-no-wrap mt-1"
                  >
                    {moment(new Date(item.datetime? item.datetime: item.created_at)).format('h:mm A')}
                  </p>) : null
                }
                <div>
                  <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-dp-lite-gray text-gray-600 mb-2 whitespace-pre-line">
                    {item.msg_body.replace(/\n/g, " \n ")}
                  </span>
                </div>
              </div>
              {selectedChannelIndex === 0 ? (
                selectedMeetingInfo.channels[selectedChannelIndex].clt_id === item.user_id ?
                  <Avatar
                    className="w-6 h-6 rounded-full order-1 avatar-top"
                    src={process.env.REACT_APP_BASE_URL + selectedMeetingInfo.channels[selectedChannelIndex].clt_avatar}
                    alt="CL"
                  /> :
                  <Avatar
                    className="w-6 h-6 rounded-full order-1 avatar-top"
                    src="/icons/avatar.svg"
                    alt={selectedMeetingInfo.contacts[item.user_id]}
                  />
              ) :
                (
                  user.user.user_role === "freelancer" ?
                    <Avatar
                      className="w-6 h-6 rounded-full order-1 avatar-top"
                      src={process.env.REACT_APP_BASE_URL + selectedMeetingInfo.channels[selectedChannelIndex].clt_avatar}
                    >CL</Avatar> : <Avatar
                      className="w-6 h-6 rounded-full order-1 avatar-top"
                      src={process.env.REACT_APP_BASE_URL + selectedMeetingInfo.channels[selectedChannelIndex].avatar}
                    >
                      {selectedMeetingInfo.channels[selectedChannelIndex].first_name.charAt(0).toUpperCase() + selectedMeetingInfo.channels[selectedChannelIndex].last_name.charAt(0).toUpperCase()}
                    </Avatar>
                )
              }
            </>) : (
            <>
              <div className='flex flex-col text-xs max-w-xs mx-2 order-2 items-start'>
                {isFirstMessageOfGroup(item, index) ?
                  (<p
                    className="time w-full text-xs ltr:left-0 rtl:right-0 bottom-0 whitespace-no-wrap mt-1"
                  >
                    {moment(new Date(item.datetime? item.datetime: item.created_at)).format('h:mm A')}
                  </p>) : null}
                <div>
                  <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-dp-lite-gray text-gray-600 mb-2 whitespace-pre-line">
                    {item.msg_body.replace(/\n/g, " \n ")}
                  </span>
                </div>
              </div>
              <span className='w-6 h-6 rounded-full order-1'></span>
            </>)
        ) : (
          <>
            <div className="flex flex-col text-xs max-w-xs mx-2 order-1 items-end">
              {isFirstMessageOfGroup(item, index) ?
                (<p
                  className="time w-full text-xs ltr:left-0 rtl:right-0 bottom-0 whitespace-no-wrap mt-1 flex justify-end"
                >
                  {moment(new Date(item.datetime? item.datetime: item.created_at)).format('h:mm A')}
                </p>) : null}
              <div>
                <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white mb-2 whitespace-pre-line">{item.msg_body.replace(/\n/g, " \n ")}</span>
              </div>
            </div>
            {isFirstMessageOfGroup(item, index) ? //sent messages
              (selectedChannelIndex === 0 ?
                (selectedMeetingInfo.channels[selectedChannelIndex].clt_id === item.user_id ? // sent group message and is client
                  <Avatar
                    className="w-6 h-6 rounded-full order-1 avatar-top"
                    src={process.env.REACT_APP_BASE_URL + user.user.avatar}
                    alt={selectedMeetingInfo.contacts[item.user_id]}
                  /> : //sent group message and is freelancer, show Letter instead avatar
                  <Avatar
                    className="w-6 h-6 rounded-full order-1 avatar-top"
                    src="/icons/avatar.svg"
                    alt={selectedMeetingInfo.contacts[item.user_id]}
                  />
                ) : (
                  <Avatar
                    className="w-6 h-6 rounded-full order-1 avatar-top"
                    src={process.env.REACT_APP_BASE_URL + user.user.avatar}
                    alt={selectedMeetingInfo.contacts[item.user_id]}
                  />
                )
              ) : <span className='w-6 h-6 rounded-full order-1'></span>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default Message;