import { useEffect, useState } from "react";
import isEmail from '../../../utils/validation/isEmail';
// import logo from './logo.svg';
// import './App.css';
import { useUser } from "../../../store/hooks";
import { LOCAL_STORAGE_KEY } from "../../../consts";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../../services";
import { Button } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";

function ResetPasswordPage(props: any) {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [resError, setResError] = useState('');


  const { resetPassword } = useUser();

  useEffect(() => {
    const { JWT_TOKEN } = LOCAL_STORAGE_KEY;

    if (localStorage.getItem(JWT_TOKEN)) {
      history.push("/home");
    }
  });

  const handleReset = async () => {

    //Validate Email and Password.
    if (password === '') {
      setPasswordError('Please input password field.');
      return;
    }
    if (password.length < 6) {
      setPasswordError('Password length must greater than 6.');
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError('Password does not matched.');
      return;
    }
    
    const res = await resetPassword({ user_id: props.match.params.id, password: password });

    if (res == 'no_user') {
      setResError('User not found.');
      return;
    }

    if (res == 'failed') {
      setResError('Something went wrong.');
      return;
    }

    if (res == 'success') {
      history.push("/home");
    }

  }


  return (
    <div>
      <div className="flex mx-auto py-6 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-6xl">
        <div className="hidden bg-cover lg:block lg:w-1/2" style={{ backgroundImage: `url("https://images.unsplash.com/photo-1606660265514-358ebbadc80d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1575&q=80")` }}></div>

        <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
          <h2 className="text-2xl font-semibold text-center text-gray-700 dark:text-white">Reset Password</h2>

          {/* <p className="text-xl text-center text-gray-600 dark:text-gray-200">Welcome back!</p> */}


          <div className="mt-4">
            <div className="flex justify-between">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="loggingPassword">Password</label>

            </div>

            <input id="loggingPassword" value={password} onChange={e => setPassword(e.target.value)} className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="password"></input>
            {passwordError && <p className="text-left text-xs text-red-500 mt-1">{passwordError}</p>}
          </div>
          <div className="mt-4">
            <div className="flex justify-between">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="loggingConfirmPassword">Confirm Password</label>

            </div>

            <input id="loggingConfirmPassword" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" type="password"></input>
            {confirmPasswordError && <p className="text-left text-xs text-red-500 mt-1">{confirmPasswordError}</p>}
          </div>
          

          <div className="mt-8">
            <button onClick={handleReset} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
              Reset Password
                    </button>
          </div>

          
        </div>
      </div>
      <SweetAlert
        title=""
        show={!!resError}
        type="warning"
        onConfirm={(response) => setResError('')}
      >
        {resError}
      </SweetAlert>
    </div>
  );
}

export default ResetPasswordPage;
