// import HttpClient from "./http-client";
import { apiUrls } from '../consts';
import { axiosService } from '.';

export const ConstsService = (function () {
  const getHashTags = async () => {
    return axiosService.get(apiUrls.HASHTAGS);
  };

  const addHashTag = async (payload: any) => {
    return axiosService.post(apiUrls.HASHTAGS, payload);
  };

  const deleteHashTag = async (id: string) => {
    return axiosService.del(apiUrls.HASHTAGS + '/' + id);
  }

  return {
    getHashTags,
    addHashTag,
    deleteHashTag
  };
})();
