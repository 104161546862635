import { useDispatch, useSelector } from "react-redux";
import { ConstsService } from "../../services";
import { CONSTS  } from "../types";
import { useProgress } from "./progress.hook";

export const useTags = () => {
  const dispatch = useDispatch();
  const { hashtags } = useSelector(({ consts }: any) => consts);
  const { startProgress, stopProgress } = useProgress();

  const getTags = async () => {
    try {
      startProgress();
      const result = await ConstsService.getHashTags();
      dispatch({
        type: CONSTS.GET_HASHTAGS_SUCCESS,
        payload: result.data.data
      });
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      dispatch({
        type: CONSTS.GET_HASHTAGS_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  const addTag = async (data: any) => {
    try {
      startProgress();
      const result = await ConstsService.addHashTag(data);
      dispatch({
        type: CONSTS.ADD_HASHTAG_SUCCESS,
        payload: result.data.data
      });
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      dispatch({
        type: CONSTS.ADD_HASHTAG_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  return {
    hashtags,
    getTags,
    addTag,
  };
};