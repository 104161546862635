export default function MeetingThreadSkeleton() {
	return (
		<div className="bg-white w-full h-16 px-3 py-2 flex items-center justify-between">
			<div className="space-y-2">
				<div className="h-4 w-14 bg-dp-gray-light animate-pulse"></div>
				<div className="h-4 w-44 bg-dp-gray-light animate-pulse"></div>
			</div>
			<div className="h-4 w-11 bg-dp-gray-light animate-pulse"></div>
		</div>
	);
}
