import Avatar from '../Common/Avatar';
import moment from 'moment-timezone';
import { useMeeting, useUser } from "../../store/hooks";
import { convertUTCToLocal } from '../../utils';

interface MeetingMemberProps {
	id: number;
	full_name: string;
	avatar?: string;
	unread?: number;
	available?: boolean;
	active?: boolean;
	rate?: any;
	lastMessageTime: string;
	fake_name?: any;
}

export default function MeetingMember({ full_name, avatar, available = true, unread, lastMessageTime, id, active, rate, fake_name }: MeetingMemberProps) {
	const { setSelectedChannelIndex, selectedChannelIndex, selectedMeetingInfo } = useMeeting();
	const { user } = useUser();

	console.log(fake_name);

	const getMeetingStatus = (schedule_at: any) => {
		return moment().isSameOrAfter(convertUTCToLocal(schedule_at)) ? true : false;
	}

	const handleClickEvent = (id: any) => {
		if (getMeetingStatus(selectedMeetingInfo?.schedule_at))
			setSelectedChannelIndex(id);
		else
			alert('You can send the message once started');
	}

	return (
		<div onClick={() => handleClickEvent(id)}>
			<a className={`${active ? 'bg-dp-blue-light' : 'bg-white'
				} flex items-center flex-shrink-0 p-2 text-left relative transition border border-dp-gray cursor-pointer rounded-md focus:outline-none`}>
				<Avatar size="h-8 w-8" url={avatar} initials={full_name.charAt(0).toUpperCase()} />
				<div className="flex flex-col justify-center flex-1 ml-3">
					{ user.user.user_role == 'client' && fake_name ?
						<span className={`text-tone-blue-very-dark text-sm font-medium leading-none mb-1`}>{full_name} {`(${fake_name})`}</span> : 
						<span className={`text-tone-blue-very-dark text-sm font-medium leading-none mb-1`}>{full_name}</span>
					}
					
					<span className="text-xs text-dp-gray-dark leading-none">
						{rate ? `$${rate}/h` : ''}
					</span>
					{unread != 0 && <div className="ml-1 h-4 w-5 bg-dp-blue-dark unread">{unread}</div>}
				</div>
			</a>
		</div>
	);
}
