import '../../../styles/tailwind.css';

import Header from '../../../layouts/header'
function HomePage() {
  return (
    <div>
      <Header></Header>
      <section className="hero-area pt-10 pb-10">
        <div className="container">
          <div className="flex justify-between">
            <div className="w-full text-center">
              <h2 className="text-4xl font-bold leading-snug text-gray-700 mb-10 wow fadeInUp" data-wow-delay="1s">Privacy & Policy
              {/* <br className="hidden lg:block"></br> Built with TailwindCSS</h2> */}
              </h2>              
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="py-24">
        <div className="container">          
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">INFORMATION COLLECTION</h3>
                  <p className="text-gray-600">At AsianGurus, we don't handle any actual personal data except for the freelancer's skills and experience. We don't collect any sensitive personal information such as names, addresses, or phone numbers. We only collect information about the freelancer's work experience, portfolio, and skills.</p>
                </div>
              </div>
            </div>            
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">USE OF INFORMATION</h3>
                  <p className="text-gray-600">The information we collect is used solely for the purpose of providing our clients with access to talented freelancers. We use the information to match freelancers with suitable clients and to facilitate the payment process.</p>
                </div>
              </div>
            </div>  
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">DATA RETENTION</h3>
                  <p className="text-gray-600">We don't keep any actual personal data. Once the freelancer's profile is deleted, all information related to the freelancer will also be deleted.</p>
                </div>
              </div>
            </div>   
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">INFORMATION SHARING AND DISCLOSURE</h3>
                  <p className="text-gray-600">We do not sell or disclose any personal information to third parties. We may share information with our payment processor, PayPal, for the purpose of processing payments.</p>
                </div>
              </div>
            </div>   
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">YOUR CHOICES AND RIGHTS</h3>
                  <p className="text-gray-600">As a freelancer, you have the right to access and update your personal information. You may also choose to delete your account at any time.</p>
                </div>
              </div>
            </div>    
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">SECURITY</h3>
                  <p className="text-gray-600">We take the security of our platform very seriously. We use industry-standard encryption to protect all data transmitted through our platform. We also use firewalls, intrusion detection systems, and other security measures to protect against unauthorized access to our systems.</p>
                </div>
              </div>
            </div> 
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">CROSS-BORDER DATA TRANSFERS</h3>
                  <p className="text-gray-600">Some of our freelancers may be located in different countries. In such cases, we may transfer data across borders. We take appropriate measures to ensure that the data is protected in accordance with applicable data protection laws.</p>
                </div>
              </div>
            </div>      
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">LINKS TO OTHER SITES</h3>
                  <p className="text-gray-600">Our platform may contain links to other websites. We are not responsible for the privacy practices or content of these websites.</p>
                </div>
              </div>
            </div>    
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">CHANGES TO THIS POLICY</h3>
                  <p className="text-gray-600">We reserve the right to modify this policy at any time. Any changes will be reflected on this page.</p>
                </div>
              </div>
            </div>   
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">CONTACT US</h3>
                  <p className="text-gray-600">If you have any questions or concerns about this policy, please contact us at support@asiangurus.com.</p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">                
                <div>
                  <h3 className="service-title">DATA PROCESSING AGREEMENT</h3>
                  <p className="text-gray-600">We may enter into a data processing agreement with our clients to ensure that we comply with applicable data protection laws. The agreement will include provisions related to data processing, security, and confidentiality.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default HomePage;
