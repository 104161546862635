import { MEETING } from '../types';
import { Meeting } from '../../models';
import { MeetingService } from "../../services";

const initialState: Meeting = {
  selectedMeetingInfo: {
    title: '',
    description: '',
    channels: [],
    contacts: {},
    id: '',
    schedule_at: ''
  },
  meetings: [],
  loading: true,
  MeetingSelected: false,
  selectedChannelIndex: -1,
  error: "",
  contactSidebarOpen: false,
  channelUnread: 0,
};

export default function meetingReducer(state = initialState, { type, payload }: any) {
  switch (type) {
    case MEETING.SET_MOBILECHATSIDEBAROPEN:
      return {
        ...state,
        mobileChatsSidebarOpen: payload,
      };
    case MEETING.SET_SELECTEDCHANNELINDEX:
      {
        let channels = state.selectedMeetingInfo.channels;

        let meetings = state.meetings;

        meetings.forEach((meeting, id) => {
          if(meeting.id == state.selectedMeetingInfo.id){
            if (payload !== -1) {
              meeting.unread = meeting.unread - channels[payload].unread;
            }
            // meeting.unread = 0;
          }
        });

        let unread = 0;
        if (payload !== -1) {
          unread = channels[payload].unread;
          channels[payload].unread = 0;
        }

        return {
          ...state,
          selectedChannelIndex: payload,
          channelUnread: unread,
          meetings: meetings,
          selectedMeetingInfo: { ...state.selectedMeetingInfo, channels: channels },
        };
      }
    case MEETING.SET_CONTACTSIDEBAROPEN:
      return {
        ...state,
        contactSidebarOpen: payload,
      };
    case MEETING.GET_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: payload,
        MeetingSelected: false,
        loading: false
      };
    case MEETING.LOAD_NEW_MEETING:
      return {
        ...state,
        meetings: [payload, ...state.meetings],
        loading: false
      };
    case MEETING.GET_MEETINGS_FAILED:
      return {
        ...state,
        error: payload,
      };
    case MEETING.GET_MEETING_INFO_SUCCESS:
      return {
        ...state,
        selectedMeetingInfo: payload,
        MeetingSelected: true,
      };
    case MEETING.GET_MEETING_INFO_FAILED:
      return {
        ...state,
        error: payload,
      };
    case MEETING.SEND_MESSAGE_SUCCESS_M:
      let channels = state.selectedMeetingInfo.channels;
      if (state.selectedChannelIndex !== -1) {

        channels[state.selectedChannelIndex].messages.push(payload);
        channels[state.selectedChannelIndex].last_message = payload.msg_body;
        channels[state.selectedChannelIndex].lastMessageTime = payload.datetime? payload.datetime: payload.created_at;
      }

      return {
        ...state,
        channelUnread: 0,
        selectedMeetingInfo: { ...state.selectedMeetingInfo, channels: channels },
      };
    case MEETING.SEND_MESSAGE_FAILED_M:
      return {
        ...state,
        error: payload,
      };
    case MEETING.CHANGE_CONTRACT:
      {
        let channels = state.selectedMeetingInfo.channels;                        
        channels[state.selectedChannelIndex].messages = payload.data;

        return {
          ...state,
          transactionUnread: 0,
          selectedMeetingInfo: { ...state.selectedMeetingInfo, channels: channels },
        };
      }
    case MEETING.LOAD_NEW_MESSAGE_MEETING:
      {
        console.log("meeting message payload => ", payload);
        let meetings = state.meetings;              
        let channels = state.selectedMeetingInfo.channels;
        let unread = state.channelUnread;
        let channel_selected = false;

        channels.forEach((channel, idx) => {
          if (channel.channel_id === payload.channel_id) {
            channel.messages.push(payload);
            channel.last_message = payload.msg_body;
            if (state.selectedChannelIndex !== idx) {
              channel.unread += 1;
            }else {
              channel_selected = true;
            }
            channel.lastMessageTime = payload.datetime? payload.datetime: payload.created_at;
            console.log("payload => ", payload);
            if(payload.chat_type == 'contract_accepted') {
              channel.has_contract = true;
            }

            if (state.selectedChannelIndex === idx) {
              MeetingService.setUnreadasRead(channel.channel_id);
            }
          }
        });
        
        if(!channel_selected){
          meetings.forEach((meeting, id) => {
            if(meeting.id == payload.job_id){
              meeting.unread = meeting.unread + 1;
            }
          });
        }

        return {
          ...state,
          channelUnread: unread,
          meetings: meetings,
          selectedMeetingInfo: { ...state.selectedMeetingInfo, channels: channels },
        };
      };
      case MEETING.SET_IS_FREE:
        let channelList = state.selectedMeetingInfo.channels;
        channelList[state.selectedChannelIndex].is_free = true;
  
        return {
          ...state,
          selectedMeetingInfo: { ...state.selectedMeetingInfo, channels: channelList },
        };
    default:
      return state;
  }
}