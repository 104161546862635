import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import MeetingTitle from './MeetingTitle';
import Chat from '../../pages/auth-pages/meeting-page/chat';

export default function MeetingNote() {	
	
	return (
		<div className={`justify-between flex-1 bg-white flex flex-col shadow-md relative`}>
			<MeetingTitle />
			<Chat/>
		</div>
	);
}
