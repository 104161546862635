import { apiUrls } from '../consts';
import { axiosService } from '.';

export const UserService = (function () {
  const getProfileData = async (payload: any) => {
    return axiosService.post(apiUrls.GET_PROFILE_BYID, { id: payload });
  }

  const getUserData = async () => {
    return axiosService.get(apiUrls.GET_USERDATA);
  };

  const addPortfolio = async (data: any) => {
    return axiosService.post(apiUrls.PORTFOLIOS, data);
  }

  const editPortfolio = async (data: any) => {
    return axiosService.put(apiUrls.PORTFOLIOS, data);
  }

  const deletePortfolio = async (id: number) => {
    return axiosService.del(apiUrls.PORTFOLIOS + '/' + id);
  }

  const updateUserData = async (data: any) => {
    return axiosService.post(apiUrls.UPDATE_USERDATA, data);
  }

  const forgotPassword = async (data: any) => {
    return axiosService.post(apiUrls.FORGOT_PASSWORD, data);
  }

  const resetPassword = async (data: any) => {
    return axiosService.post(apiUrls.RESET_PASSWORD, data);
  }

  const getAvailableAvatars = async () => {
    return axiosService.get(apiUrls.GET_AVAILABE_AVATARS);
  };

  const getStripeConnectUrl = async () => {
    return axiosService.get(apiUrls.GET_STRIPE_CONNECT_URL);
  }

  return {
    getUserData,
    getProfileData,
    addPortfolio,
    editPortfolio,
    deletePortfolio,
    updateUserData,
    getAvailableAvatars,
    forgotPassword,
    resetPassword,
    getStripeConnectUrl
  };
})();
