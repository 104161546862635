export const CONSTS = {
  GET_HASHTAGS: "GET_HASHTAGS",
  GET_HASHTAGS_SUCCESS: "GET_HASHTAGS_SUCCESS",
  GET_HASHTAGS_FAILED: "GET_HASHTAGS_FAILED",
  ADD_HASHTAG: 'ADD_HASHTAG',
  ADD_HASHTAG_SUCCESS: 'ADD_HASHTAG_SUCCESS',
  ADD_HASHTAG_FAILED: 'ADD_HASHTAG_FAILED',
  DELETE_HASHTAG: 'DELETE_HASHTAG',
  DELETE_HASHTAG_SUCCESS: 'DELETE_HASHTAG_SUCCESS',
  DELETE_HASHTAG_FAILED: 'DELETE_HASHTAG_FAILED',
}