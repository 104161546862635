import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { useConsts, useUser } from '../../../store/hooks'
import Header from '../../../layouts/header'
import MaterialTable, { MTableToolbar } from 'material-table'
import TagModal from '../../../components/Tag-Modal'

function TagsPage() {
  const history = useHistory();
  const { hashtags, getHashTags, createHashTag, deleteHashTag } = useConsts();
  const [showModal, setShowModal] = useState(false);
  const { user } = useUser();

  const deleteHashtag = (id: string) => {
    deleteHashTag(id);
  }
  const columns = [
    {
      title: 'Name',
      field: 'hashtag_name',
      render: (rowData: any) => rowData.hashtag_name
    },
    {
      title: 'Action',
      render: (rowData: any) =>
        <div className="flex">
          <button className="p-2 bg-red-300 text-white" onClick={() => deleteHashtag(rowData.hashtag_id)}>Delete</button>
        </div>
    }
  ];

  useEffect(() => {
    const init = async () => {
      await getHashTags();
    }
    init();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <section className="bg-gray-100 h-full body-font pt-20 overflow-auto">
        <div className="container">
          <MaterialTable
            style={{ padding: "1rem", boxShadow: 'none' }}
            columns={columns}
            data={hashtags}
            title={<p className="text-blue-500 text-4xl font-semibold">HashTags</p>}
            options={{
              sorting: true,
              headerStyle: {
                backgroundColor: 'rgb(249, 250, 251)',
                fontSize: "1.2rem",
              }
            }}
            components={{
              Toolbar: props => (
                <div className="flex justify-between items-center mb-8">
                  <div className="w-full">
                    <MTableToolbar {...props} />
                  </div>
                  
                  <div className="w-60 flex justify-end">
                    <button onClick={(e: any) => setShowModal(true)} className="secondary-btn">Add Hashtag</button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        {showModal ? <TagModal setShowModal={setShowModal}></TagModal> : null}
      </section>
    </>
  );
}

export default TagsPage;
