import { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import { LOCAL_STORAGE_KEY } from "../consts";
import { useUser, useTransaction, useMeeting, usePostJob } from "../store/hooks";

function Header() {
  const history = useHistory();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [showDraftOptionModal, setShowDraftOptionModal] = useState(false);
  const [currentNav, setCurrentNav] = useState('');
  const { JWT_TOKEN } = LOCAL_STORAGE_KEY;
  const token = localStorage.getItem(JWT_TOKEN);
  const { logout, user, setUnreadTransaction, setUnreadMeeting } = useUser();

  const { transactions } = useTransaction();
  const { meetings } = useMeeting();
  const { initJobInfo } = usePostJob();

  // const navigationRef = useRef();
  const onHandleLogout = async (e: any) => {
    e.preventDefault();
    await logout();
    window.location.href = "/";
  }

  const onLogo = (e: any) => {
    e.preventDefault();
    if (token || user.token)
      history.push('/profile/me');
    else
      history.push('/home');
  }

  const onLoginButton = (e: any) => {
    e.preventDefault();
    history.push("/auth/login");
  }

  const onProfileButton = (e: any) => {
    e.preventDefault();
    history.push("/profile/me");
  }

  // const onClientRegisterButton = () => {
  //   history.push("/client/signup");
  // }
  const onHireButton = (e: any) => {
    e.preventDefault();
    initJobInfo();
    if ((token || user.token) && user.user.draftjob) {
      setShowDraftOptionModal(true);
    } else {
      history.push('/client/post-job');
    }
  }
  const goDraft = () => {
    setShowDraftOptionModal(false);
    history.push('/client/post-job?draft=true');
  }

  const goStartJob = () => {
    setShowDraftOptionModal(false);
    history.push('/client/post-job');
  }

  const onMeeting = (e: any) => {
    e.preventDefault();
    setUnreadMeeting(0);
    history.push("/meetings");
  }

  const onTickets = (e: any) => {
    e.preventDefault();
    history.push("/tickets");
  }

  const onTags = (e: any) => {
    e.preventDefault();
    history.push("/tags");
  }

  const onTransaction = (e: any) => {
    e.preventDefault();
    setUnreadTransaction(0);
    history.push("/transaction");
  }

  const onFreelancerRegisterButton = (e: any) => {
    e.preventDefault();
    history.push("/freelancer/signup");
  }

  const onSetting = (e: any) => {
    e.preventDefault();
    history.push("/setting");
  }

  const handleScroll = () => {

  }

  // const showUserMenu = () => {
  //   if (isUserMenuOpen === true)
  //     setIsUserMenuOpen(false);
  //   else
  //     setIsUserMenuOpen(true);
  // }

  const handleClick = (e: any) => {
    setIsUserMenuOpen(false);
  }

  const calcUnreadMeeting = (meetings) => {
    let unread = 0;
    meetings.forEach(_meeting => {
      unread = unread + _meeting?.unread;
    });

    return unread;
  }

  const calcUnreadTransaction = (transactions) => {
    let unread = 0;
    transactions.forEach(_transaction => {
      unread = unread + _transaction?.unread;
    });

    return unread;
  }

  useEffect(() => {
    let curUrl = window.location.href;
    if (curUrl.includes('transaction')) { setCurrentNav('transaction'); }
    if (curUrl.includes('meeting')) { setCurrentNav('meeting'); }
    if (curUrl.includes('summary')) { setCurrentNav('summary'); }
    if (curUrl.includes('ticket')) { setCurrentNav('ticket'); }
    if (curUrl.includes('tags')) { setCurrentNav('tags'); }
    if (!curUrl.includes('transaction') && !curUrl.includes('meeting') && !curUrl.includes('summary') && !curUrl.includes('ticket') && !curUrl.includes('tags')) {
      setCurrentNav('');
    }

    setTimeout(() => {
      window.addEventListener('click', handleClick);
    }, 0);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  
  useEffect(() => {
    let curUrl = window.location.href;
    if (curUrl.includes('transaction')) { setCurrentNav('transaction'); }
    if (curUrl.includes('meeting')) { setCurrentNav('meeting'); }
    if (curUrl.includes('summary')) { setCurrentNav('summary'); }
    if (curUrl.includes('ticket')) { setCurrentNav('ticket'); }
    if (curUrl.includes('tags')) { setCurrentNav('tags'); }
    if (!curUrl.includes('transaction') && !curUrl.includes('meeting') && !curUrl.includes('summary') && !curUrl.includes('ticket') && !curUrl.includes('tags')) {
      setCurrentNav('');
    }
  }, [window.location.href])

  return (
    <div>
      {showDraftOptionModal &&
        <div
          className="justify-center items-center flex w-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          style={{background: 'rgba(100,100,100,0.8)'}}
        >
          <div className="relative my-6 mx-auto" style={{ width: "500px" }}>
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl font-bold">
                  Create a Job
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowDraftOptionModal(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative px-6 py-2 flex-auto w-full">
                <button
                  className="background-transparent font-bold px-6 py-2 text-sm mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => goDraft()}
                >
                  Create a job from Draft
                </button>
                <button
                  className="background-transparent font-bold px-6 py-2 text-sm mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => goStartJob()}
                >
                  Create a job from scratch
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <header id="header-wrap" className="relative" onScroll={handleScroll}>
        <div id="navigation" className="navigation left-0 w-full z-30 duration-300">
          <div className="container">
            <nav className="navbar py-2 h-16 navbar-expand-lg flex justify-between items-center relative duration-300">
              <a className="navbar-brand flex" href="void(0)" onClick={onLogo}>
                <img src="/logo.png" className="h-12 w-12" alt="Logo"></img>
                <div className="logo-title">ASIANGURUS</div>
              </a>

              {token || user.token !== '' ?

                <div className="lg:flex sm:block sm:absolute sm:right-0 sm:mr-16 lg:static lg:mr-0 items-center">
                  <div className="collapse navbar-collapse hidden lg:block duration-300 shadow absolute top-100 left-0 mt-full bg-white z-20 px-5 py-3 w-full lg:static lg:bg-transparent lg:shadow-none" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto justify-center items-center lg:flex">
                      {user.user.user_role !== "admin" &&
                        <>
                          <li className="nav-item flex items-center">
                            <a className={`page-scroll font-normal ${currentNav === 'meeting' ? 'active' : ''}`} href="void(0)" onClick={onMeeting}>MEETING</a>
                            {calcUnreadMeeting(meetings) !== 0 && (
                              <div
                                className='-ml-5 flex items-center justify-center w-5 h-5 rounded-full text-xs text-center bg-blue-500 text-white'
                              >
                                {calcUnreadMeeting(meetings)}
                              </div>
                            )}
                          </li>
                          <li className="nav-item flex items-center">
                            <a className={`page-scroll font-normal ${currentNav === 'transaction' ? 'active' : ''}`} href="void(0)" onClick={onTransaction}>TRANSACTIONS</a>
                            {calcUnreadTransaction(transactions) !== 0 && (
                              <div
                                className='-ml-5 flex items-center justify-center w-5 h-5 rounded-full text-xs text-center bg-blue-500 text-white'
                              >
                                {calcUnreadTransaction(transactions)}
                              </div>
                            )}
                          </li>
                          <li className="nav-item flex items-center">
                            <Link className={`page-scroll font-normal ${currentNav === 'summary' ? 'active' : ''}`} to="/summary">SUMMARY</Link>
                          </li>
                        </>
                      }
                      <li className="nav-item">
                        <a className={`page-scroll font-normal ${currentNav === 'ticket' ? 'active' : ''}`} href="void(0)" onClick={onTickets}>TICKETS</a>
                      </li>
                      {user.user.user_role == "admin" &&
                        <li className="nav-item">
                          <a className={`page-scroll font-normal ${currentNav === 'tags' ? 'active' : ''}`} href="void(0)" onClick={onTags}>HASHTAGS</a>
                        </li>
                      }
                      {user.user.user_role === "client" ?
                        <li className="nav-item">
                          <a className="primary-btn" href="void(0)" onClick={onHireButton}>Hire&nbsp;Now</a>
                        </li> : null
                      }
                    </ul>
                  </div>

                  <div className="relative">
                    <div>
                      <button type="button" onClick={(e) => {
                        e.stopPropagation();
                        setIsUserMenuOpen(!isUserMenuOpen);
                      }} className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                        <span className="sr-only">Open user menu</span>
                        {(user.user.avatar === null || user.user.avatar === undefined) ?
                          <img className="w-10 h-10 rounded-full" src="/assets/imgs/avatar.png" alt=""></img> :
                          <img className="w-10 h-10 rounded-full" src={process.env.REACT_APP_BASE_URL + user.user.avatar} alt=""></img>
                        }
                      </button>
                    </div>
                    {isUserMenuOpen ?
                      <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a href="/profile/me" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Profile</a>
                        <a href="/setting" onClick={onSetting} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
                        <a href="void(0)" onClick={(e: any) => onHandleLogout(e)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
                      </div>
                      : ""}
                  </div>
                </div>
                :
                <div className=" sm:block sm:absolute sm:right-0 sm:mr-16 lg:static lg:mr-0">
                    <a className="text-black font-normal px-6 py-3 duration-300  hover:text-green-600" href="/privacy">Privacy</a>
                  <a className="text-black font-normal px-6 py-3 duration-300  hover:text-green-600" href="/teams">Develop Team</a>
                  <a className="text-black font-normal px-6 py-3 duration-300  hover:text-green-600" href="/support">Support</a>
                  <a className="text-black font-normal px-6 py-3 duration-300  hover:text-green-600" href="void(0)" onClick={onFreelancerRegisterButton}>Apply&nbsp;as&nbsp;a&nbsp;freelancer</a>
                  <a className="primary-btn" href="void(0)" onClick={onHireButton}>Hire&nbsp;Now</a>
                  <a onClick={onLoginButton} className="text-black font-normal px-6 py-3 duration-300  hover:text-green-600" href="void(0)">Log&nbsp;In</a>
                </div>
              }
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
