import '../../../styles/tailwind.css';

import Header from '../../../layouts/header';
import Footer from '../../../layouts/footer';

function HomePage() {
  return (
    <div>
      <Header></Header>
      <section className="hero-area pt-48 pb-10">
        <div className="container">
          <div className="flex justify-between">
            <div className="w-full text-center">
              <h2 className="text-4xl font-bold leading-snug text-gray-700 mb-10 wow fadeInUp" data-wow-delay="1s">Find experienced Developers!
                {/* <br className="hidden lg:block"></br> Built with TailwindCSS</h2> */}
              </h2>
              <div className="text-center mb-10 wow fadeInUp" data-wow-delay="1.2s">
                <a href="/client/post-job"
                  rel="nofollow"
                  className="primary-btn text-xl px-10 py-4">Get Started</a>
              </div>
              <div className="text-center wow fadeInUp" data-wow-delay="1.6s">
                <img className="img-fluid mx-auto" src="assets/img/hero.svg" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="py-24">
        <div className="container">
          <div className="text-center">
            <h2 className="mb-12 section-heading wow fadeInDown" data-wow-delay="0.3s">Services</h2>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">
                <div className="icon text-5xl">
                  <i className="lni lni-cog"></i>
                </div>
                <div>
                  <h3 className="service-title">Web Development</h3>
                  <p className="text-gray-600">AsianGurus offers web development services for businesses and individuals who need custom websites, e-commerce platforms, or web applications. You can find professional web developers who specialize in various programming languages and platforms. AsianGurus's non-negotiable hourly rate starts at $10, with rates increasing only after the freelancer's skills have been approved.</p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.6s">
                <div className="icon text-5xl">
                  <i className="lni lni-bar-chart"></i>
                </div>
                <div>
                  <h3 className="service-title">Mobile Development</h3>
                  <p className="text-gray-600">AsianGurus provides mobile development services for businesses and individuals who need native or cross-platform mobile applications. You can find professional mobile app developers who specialize in iOS and Android platforms, as well as app design, testing, and deployment. Hourly rates for mobile development projects start at $10, and rates increase only when the freelancer's skills are approved.</p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="0.9s">
                <div className="icon text-5xl">
                  <i className="lni lni-briefcase"></i>
                </div>
                <div>
                  <h3 className="service-title">AI and Machine Learning</h3>
                  <p className="text-gray-600">AsianGurus offers AI and machine learning services for businesses that want to leverage data analytics and machine learning algorithms. You can find professionals who specialize in natural language processing, computer vision, deep learning, and other AI technologies. Hourly rates start at $10 and increase only when the freelancer's skills are approved.</p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="1.2s">
                <div className="icon text-5xl">
                  <i className="lni lni-pencil-alt"></i>
                </div>
                <div>
                  <h3 className="service-title">Cloud Management</h3>
                  <p className="text-gray-600">AsianGurus provides cloud management services to help businesses migrate their infrastructure to cloud platforms. You can find professionals who can help you optimize your cloud infrastructure, ensure security, and provide support for your cloud-based applications. Hourly rates start at $10, and rates increase only when the freelancer's skills are approved.</p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="1.5s">
                <div className="icon text-5xl">
                  <i className="lni lni-mobile"></i>
                </div>
                <div>
                  <h3 className="service-title">Business Applications</h3>
                  <p className="text-gray-600">AsianGurus offers business application development services for businesses that need custom solutions to manage their operations, processes, and workflows. You can find professionals who specialize in developing ERP, CRM, HRMS, and other business applications that are tailored to your business needs. Hourly rates start at $10 and increase only when the freelancer's skills are approved.</p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
              <div className="m-4 wow fadeInRight" data-wow-delay="1.8s">
                <div className="icon text-5xl">
                  <i className="lni lni-layers"></i>
                </div>
                <div>
                  <h3 className="service-title">MVP</h3>
                  <p className="text-gray-600">AsianGurus provides MVP development services for startups that want to test their product ideas with early adopters. You can find professionals who can help you define your product vision, create a prototype, and develop an MVP that can be tested in the market. Hourly rates start at $10, and rates increase only when the freelancer's skills are approved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="app-download">        
        <h2 className="text-xl font-semibold text-center">Download our app</h2>
        <p className="text-sm text-center m-4">Get the latest version of our app for your mobile device.</p>
        <div className="text-center">
          <a href="https://apps.apple.com/us/app/plusportfolio/id1670780891?itsct=apps_box_badge&amp;itscg=30200" className='store-button'>
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=150x53&amp;releaseDate=1684540800" alt="Download on the App Store" className='store-img'/>
          </a>          
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default HomePage;
