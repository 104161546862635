import { useDispatch, useSelector } from "react-redux";
import { PostJobService } from "../../services";
import { useNotification } from './notification.hook';
import { POSTJOB, NOTI_TYPE, USER } from "../types";
import { useProgress } from "../hooks";
import { LOCAL_STORAGE_KEY } from "../../consts";

const { POST_JOB } = LOCAL_STORAGE_KEY;

export const usePostJob = () => {
  const dispatch = useDispatch();
  const { jobInfo, topFreelancers, confirmCode } = useSelector(({ postJob }: any) => postJob);
  const { setNewNotification } = useNotification();
  const { startProgress, stopProgress } = useProgress();

  const setJobInfo = (payload: any) => {
    dispatch({
      type: POSTJOB.SET_JOBINFO,
      payload
    });
    console.log("Save posting job into localstorage");
    console.log(payload);
    console.log(JSON.stringify(payload));
    localStorage.setItem(POST_JOB, JSON.stringify(payload));
    return true;
  };

  const initJobInfo = () => {
    //initialize
    console.log("reset job info ===");
    let payload = {
      job_id: "",
      job_title: "",
      job_desc: "",
      job_status: "Active",
      job_tags: [],
      user_id: "",
      job_schedule: ""
    }
    dispatch({
      type: POSTJOB.SET_JOBINFO,
      payload,
    });
    localStorage.removeItem(POST_JOB);
    return true;
  }

  const saveDraft = async (payload: any) => {
    try {
      startProgress();
      let data = await PostJobService.saveDraft(payload);
      dispatch({
        type: USER.UPDATE_DRAFT_JOB,
        payload: data,
      });
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      // setNewNotification(NOTI_TYPE.WARNING, "Job post error.");
      return false;
    }
  }

  const addJobInfo = async () => {
    try {
      startProgress();
      await PostJobService.addJobInfo(jobInfo, topFreelancers);
      initJobInfo();
      setNewNotification(NOTI_TYPE.SUCCESS, "Job successfully posted.");
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      setNewNotification(NOTI_TYPE.WARNING, "Job post error.");
      return false;
    }
  }

  const getTopFreelancers = async (jobInfo: any) => {
    try {
      const data = await PostJobService.getTopFreelancers(jobInfo);
      const payload = data.data.data;
      dispatch({ type: POSTJOB.GET_TOPFREELANCERS_SUCCESS, payload });
      return true;
    } catch ({ response, message }) {

      dispatch({
        type: POSTJOB.GET_TOPFREELANCERS_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  const getConfirmCode = async (email: any) => {
    try {
      startProgress();
      const result = await PostJobService.getConfirmCode(email);
      setNewNotification(NOTI_TYPE.SUCCESS, "Sent confirm code to email!");
      stopProgress();
      return result.data.data;
    } catch ({ response, message }) {
      
      stopProgress();
      if(response && response.data && response.data.message == 'email_exist') {
        setNewNotification(NOTI_TYPE.WARNING, "The email is already exist!");  
      } else {
        setNewNotification(NOTI_TYPE.WARNING, "Failed to sent confirm code!");
      }
      return '';
    }
  }

  return {
    jobInfo,
    topFreelancers,
    confirmCode,
    initJobInfo,
    getConfirmCode,
    getTopFreelancers,
    setJobInfo,
    addJobInfo,
    saveDraft,
  };
};