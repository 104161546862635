import { useDispatch, useSelector } from "react-redux";
import { ConstsService } from "../../services";
import { CONSTS } from "../types";

export const useConsts = () => {
  const dispatch = useDispatch();
  const { hashtags } = useSelector(({ consts }: any) => consts);

  const getHashTags = async () => {
    try {
      const results = await ConstsService.getHashTags();
      dispatch({ type: CONSTS.GET_HASHTAGS_SUCCESS, payload: results.data.data });
      return true;
    } catch ({ response, message }) {

      dispatch({
        type: CONSTS.GET_HASHTAGS_FAILED,
        payload: response?.data?.message || "Something went wrong."
      });
      return false;
    }
  }
  const createHashTag = async (payload: any) => {
    try {
      const result = await ConstsService.addHashTag(payload);
      dispatch({
        type: CONSTS.ADD_HASHTAG_SUCCESS,
        payload: result.data.data
      });
      return true;
    } catch ({ response, message }) {
      dispatch({
        type: CONSTS.ADD_HASHTAG_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  const deleteHashTag = async (id: string) => {
    try {
      await ConstsService.deleteHashTag(id);
      dispatch({
        type: CONSTS.DELETE_HASHTAG_SUCCESS,
        payload: id
      });
      return true;
    } catch ({ response, message }) {
      dispatch({
        type: CONSTS.DELETE_HASHTAG_FAILED,
        payload: "message"
      });
      return false;
    }
  }
  return {
    hashtags,
    getHashTags,
    createHashTag,
    deleteHashTag
  };
};
