import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import StatusIcon from './StatusIcon';
import { useState, useRef, useEffect } from 'react';
import { useMeeting, useUser } from "../../../store/hooks";
import SweetAlert from 'react-bootstrap-sweetalert';
import ContractModal from '../../../components/Contract-Modal'
import { convertUTCToLocal } from '../../../utils';
import Message from "../../../components/ChatBox/Message";
import TimeLine from "../../../components/ChatBox/TimeLine";
import Contract from "../../../components/ChatBox/Contract";

const useStyles = makeStyles(theme => ({
  messageRow: {
    '&.contact': {
      '& .bubble': {
        backgroundColor: "rgb(226,232,240)",
        color: "black",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: '1px',
        wordBreak: "break-all",
        width: "fit-content",
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopLeftRadius: 10
        }
      },
      '&.last-of-group': {
        marginBottom: "1.5rem",
        '& .bubble': {
          borderBottomLeftRadius: 10,
        }
      }
    },
    '&.me': {
      '& .avatar': {
        order: 2,
        margin: '0 0 0 16px'
      },
      '& .bubble': {
        marginLeft: 'auto',
        backgroundColor: "rgb(49,130,206)",
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: '1px',
        wordBreak: "break-all",
        width: "fit-content",
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopRightRadius: 10
        }
      },

      '&.last-of-group': {
        marginBottom: "1.5rem",
        '& .bubble': {
          borderBottomRightRadius: 10,
        }
      }
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 10,
      }
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 10,
      }
    }
  }
}));

const Chat = (props: any) => {

  const messageEl = useRef<HTMLDivElement>(null);
  const { selectedMeetingInfo, selectedChannelIndex, channelUnread, sendMessage, endQA } = useMeeting();
  const { user } = useUser();
  const [messageText, setMessageText] = useState('');

  const countdownRef = useRef(120);
  const [countdown, setCountdown] = useState(countdownRef.current)

  const onInputChange = (ev: any) => {
    setMessageText(ev.target.value);
  }

  const onMessageSubmit = (ev: any) => {
    ev.preventDefault();
    if (messageText === '' || messageText.trim() === '') {
      return;
    }

    if (user.user.user_role === "freelancer" && countdownRef.current === 0) {
      return;
    }

    sendMessage({
      user_id: user.user.user_id,
      msg_body: messageText,
      channel_id: selectedMeetingInfo.channels[selectedChannelIndex].channel_id,
      created_at: new Date()
    });

    setMessageText('');

    if (countdownTimer.current) clearTimeout(countdownTimer.current);
    countdownRef.current = 120;
    setCountdown(countdownRef.current);
  }

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.ctrlKey)
      onMessageSubmit(e)
  }

  const getMeetingStatus = (schedule_at: any) => {
    return moment().isSameOrAfter(convertUTCToLocal(schedule_at)) ? true : false;
  }

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 8) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
      var target: any = messageEl.current;
      if (target)
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (messageEl.current !== null && selectedChannelIndex !== -1) {
      var target: any = messageEl.current;
      if (target)
        target.scroll({ top: target.scrollHeight });
      messageEl.current.addEventListener('DOMNodeInserted', (event: any) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [selectedChannelIndex]);// eslint-disable-line react-hooks/exhaustive-deps

  const lastLengthMessages = useRef(0)
  useEffect(() => {
    if (selectedChannelIndex === -1)
      return;

    const messages = selectedMeetingInfo.channels[selectedChannelIndex].messages;
    const newLengthMessages = messages.length;

    if (newLengthMessages === 0)
      return;

    if (user.user.user_role === "freelancer" && messages[newLengthMessages - 1].user_id === user.user.user_id)
      return;

    if (lastLengthMessages.current !== 0 && newLengthMessages > lastLengthMessages.current) {
      if (countdownTimer.current)
        clearTimeout(countdownTimer.current);

      countdownRef.current = 120;
      setCountdown(countdownRef.current);
      countdownHandler();
    }

    lastLengthMessages.current = newLengthMessages;
  }, [selectedMeetingInfo, selectedChannelIndex, channelUnread])

  const countdownTimer = useRef<NodeJS.Timeout>();
  const countdownHandler = () => {
    countdownTimer.current = setTimeout(() => {
      setCountdown(--countdownRef.current)

      if (countdownRef.current > 0) {
        countdownHandler();
      }
    }, 1000);
  }

  return (
    <>
      <div className="relative flex-1">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="h-full max-h-full p-6 mb-2 space-y-3.5 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch" ref={messageEl}>
            <div className="mt-auto"></div>
            {selectedChannelIndex !== -1 ? (<>
              {
                selectedMeetingInfo.channels[selectedChannelIndex] && selectedMeetingInfo.channels[selectedChannelIndex].messages.length > 0 ? (
                  <div className="max-w-screen-lg mx-auto w-full">
                    {selectedChannelIndex === 0 ? (
                      <div className="text-center mx-16 mb-4">
                        <p className="text-black text-3xl">{selectedMeetingInfo.title}</p>
                        <p className="break-words whitespace-pre-line">{selectedMeetingInfo.description}</p>
                      </div>
                    ) : null}
                    {selectedMeetingInfo.channels[selectedChannelIndex].messages.map((item: any, i: any) => {
                      return (<div key={i}>
                        <TimeLine item={item} index={i} />

                        {(item?.chat_type == null || item?.chat_type === "m_chat") &&
                          <Message item={item} index={i} />
                        }

                        {(item?.chat_type != null && item?.chat_type !== "m_chat") &&
                          <Contract item={item} index={i} />
                        }
                      </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 items-center justify-center">
                      <Icon className="text-128" color="disabled">
                        chat
                      </Icon>
                    </div>
                  </div>
                )
              }</>) :
              (
                <div className="flex flex-col flex-1">
                  <div className="flex flex-col flex-1 items-center justify-center">
                    <div className="text-3xl text-gray-500 text-center">
                      {selectedMeetingInfo ? "Select the meeting!" :
                        getMeetingStatus(selectedMeetingInfo?.schedule_at) ? "Select to start chat" :
                          <span>You can send the message once started the meeting. <br />Meeting will be started at {convertUTCToLocal(selectedMeetingInfo?.schedule_at).format('Do MMM, YYYY h:mm a')}</span>
                      }
                    </div>
                    <Icon className="text-5xl" color="disabled">
                      chat
                    </Icon>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      {getMeetingStatus(selectedMeetingInfo?.schedule_at) && selectedChannelIndex !== -1 &&
        <>
          <div className="mx-4 mt-4 rounded-lg bg-dp-gray-very-light text-dp-gray-very-dark">
            <div className="flex items-center justify-between px-3 py-4">
              <textarea
                className="resize-none overflow-hidden  block border-0 px-0 placeholder-dp-gray-dark py-0 focus:ring-0 w-full text-sm bg-transparent focus:outline-none break-word"
                value={messageText}
                placeholder={user.user.user_role === 'client' ? 'Enter the question' : 'Answer for the question'}
                onKeyDown={(e: any) => onKeyDown(e)}
                onKeyUp={(e: any) => onKeyUp(e)}
                onChange={onInputChange}
                rows={1}
              ></textarea>
              <button onClick={onMessageSubmit} className={`focus:outline-none`} disabled={countdown === 0 && user.user.user_role === "freelancer"}>
                <svg
                  className={`text-dp-blue-dark transition w-5 h-5`}
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M1.393 20.001a1.347 1.347 0 01-1.241-1.963c.848-1.719 1.742-3.416 2.555-5.151a2.654 2.654 0 011.916-1.531C5.8 11.067 7 10.906 8.2 10.734c.7-.1 1.4-.182 2.1-.278a.967.967 0 00.323-.1.382.382 0 00.208-.416.419.419 0 00-.365-.363c-.378-.061-.759-.106-1.139-.16a44.026 44.026 0 01-4.714-.785 3.207 3.207 0 01-1.128-.469 1.807 1.807 0 01-.56-.648C2 5.667 1.063 3.815.147 1.955A1.343 1.343 0 011.166.015a1.428 1.428 0 01.881.176q4.861 2.433 9.725 4.862 3.682 1.841 7.365 3.679A1.361 1.361 0 0120 9.915a1.341 1.341 0 01-.846 1.347q-2.646 1.313-5.285 2.641l-11.81 5.898a1.436 1.436 0 01-.666.2"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex justify-end max-w-screen-lg mx-auto w-full  mb-3">
            <p className="text-sm">Press <i>Ctrl+Enter</i> to send a message.</p>
          </div>
        </>
      }
    </>
  );
}

export default Chat;
