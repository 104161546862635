// import MeetingMembers from './MeetingMembers';

import { useUser, useTransaction } from "../../store/hooks";
import moment from 'moment-timezone';

// import MeetingInfo from './MeetingInfo';
export default function RightSidebar() {
  const { user } = useUser();
  const { transactions, selectedTransactionId } = useTransaction();
  const selectedTransaction = transactions[selectedTransactionId];

  return (
    <div className="bg-white w-250 border-l border-dp-gray p-2">
      <div className="flex flex-col h-full">
        <div className="space-y-4 divide-y divide-gray-200 break-words overflow-y-scroll" style={{height: 'calc(100vh - 100px)'}}>
          <div>
            <h3 className="text-lg leading-6 font-bold text-gray-900s">
              Job Status
            </h3>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Title
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.contract_title}</span>
            </div>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Description
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.contract_desc || '-'}</span>
            </div>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Total Working Hours/{user.user.user_role === "freelancer" ? "Total Amount" : "Total Spent"}
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.total_hrs || '0'} hours / ${selectedTransaction?.total_spent || '0'}</span>
            </div>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Payment Status
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{!selectedTransaction?.paid_first_invoice &&  'no deposit'}{selectedTransaction?.has_pending_invoice &&  'invoice not paid'}{!selectedTransaction?.has_pending_invoice && selectedTransaction?.paid_first_invoice  && 'All paid'}</span>
            </div>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Status
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.contract_status || '-'}</span>
            </div>
          </div>
          <div className="pt-2">
            <h3 className="text-lg leading-6 font-bold text-gray-900s">
              {user.user.user_role === "freelancer" ? "Client Info" : "Freelancer Info"}
            </h3>
            <div className="mt-2">
              <p className="ml-1 mt-1 text-sm text-gray-500">
                - Name
              </p>
              <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.full_name}</span>
            </div>
            {user.user.user_role !== "freelancer" &&
              <>
                <div className="mt-2">
                  <p className="ml-1 mt-1 text-sm text-gray-500">
                    - English Level
                  </p>
                  <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.english_level || '-'}</span>
                </div>

                <div className="mt-2">
                  <p className="ml-1 mt-1 text-sm text-gray-500">
                    - Timezone
                  </p>
                  <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"> {selectedTransaction?.timezone ? moment().tz(selectedTransaction?.timezone).zoneAbbr(): '-'}</span>
                </div>
                <div className="mt-2">
                  <p className="ml-1 mt-1 text-sm text-gray-500">
                    - Skype ID
                  </p>
                  <span className="ml-3 text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">{selectedTransaction?.skype_id || '-'}</span>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
