import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MeetingThread from './MeetingThread';
import Separator from '../Common/Separator';
import NoMeetingThreads from './NoMeetingThreads';
import LoadingMeetingThreads from './LoadingMeetingThreads';
import SidebarTitle from '../Common/SdiebarTitle';
import { convertUTCToLocal } from '../../utils';
import moment from "moment-timezone";
import { useMeeting, useUser } from '../../store/hooks';


export default function MeetingThreads() {
	const { setSelectedChannelIndex, getMeetings, meetings, loading, getMeetingInfoById, selectedMeetingInfo } = useMeeting();
	const { setUnreadMeeting } = useUser();
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		const initial = async () => {
			await getMeetings();
			setUnreadMeeting(0);
		};

		initial();
		setSelectedChannelIndex(-1);

	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	const filtered = useMemo(() => {
		if (meetings.length == 0) return [];
		return meetings.filter((thread: any) => {
			return thread.title.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [searchValue, meetings, loading]);

	const selectMeeting = async (id: any) => {
		setSelectedChannelIndex(-1);
		await getMeetingInfoById(id);
	}

	const getMeetingStatus = (schedule_at: any) => {
		return moment().isSameOrAfter(convertUTCToLocal(schedule_at)) ? "In Progress" : "Start At: " + convertUTCToLocal(schedule_at).format('Do MMM, YYYY h:mm a');
	}

	return (
		<>
			<SidebarTitle
				value={searchValue}
				onChange={event => setSearchValue(event.target.value)}
				onClose={() => setSearchValue('')}
				title="Meetings"
				hasSearch={true}
				placeholder="Search meetings..."
			/>
			{loading === true && <LoadingMeetingThreads />}
			{filtered.length === 0 && loading === false && (
				<>
					<NoMeetingThreads />
				</>
			)}
			{filtered.length > 0 && loading == false && (
				<div className={`${loading ? 'opacity-50' : 'opacity-100'} transition flex flex-1 flex-col space-y-2.5 mb-2`}>
					<div className="relative flex-1">
						<div className="absolute top-0 left-0 w-full meeting-side ">
							<div className="flex flex-col max-h-full mb-2 space-y-2.5 overflow-y-auto">
								{filtered.map((thread: any, id: any) => (
									<MeetingThread
										key={id}
										id={thread.id}
										title={thread.title}
										onClick={() => selectMeeting(thread.id)}
										status={(thread.id == selectedMeetingInfo?.id) ? 1 : -1}
										note={getMeetingStatus(thread?.schedule_at)}
										unread={thread.unread > 0}
									/>
								))}
							</div>
							<div>
								<Separator />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
