import { useEffect, useMemo, useState } from 'react';
import { useMeeting, useUser } from "../../store/hooks";
import LoadingMeetingMembers from './LoadingMeetingMembers';
import MeetingMember from './MeetingMember';
import Separator from '../Common/Separator';

export default function MeetingInfo() {
    const { selectedMeetingInfo, MeetingSelected } = useMeeting();	
    const { user } = useUser();
	const [statusMenuEl, setStatusMenuEl] = useState(null);

    const handleStatusMenuClick = (event: any) => {
        event.preventDefault();
        setStatusMenuEl(event.currentTarget);
    };
    
    const handleStatusClose = (event: any) => {
        event.preventDefault();
        setStatusMenuEl(null);
    };
    
      const handleStatusSelect = (event: any, status: any) => {
        event.preventDefault();
        setStatusMenuEl(null);
    }

	return (
		<>			
			{!MeetingSelected ? (
				// <LoadingMeetingMembers />
				<></>
			) : (
				<div className={`${!MeetingSelected ? 'opacity-50' : 'opacity-100'} transition flex flex-col flex-1 space-y-2`}>
					<div className="relative flex-1">
						<div className="absolute top-0 left-0 w-full h-full">
							<div className="flex flex-col max-h-full mb-2 overflow-y-auto">
                                <p className="ml-3">* You are {selectedMeetingInfo.contacts[user.user.user_id]} In the Group Chat</p>
							</div>
							<Separator />
						</div>
					</div>
				</div>
			)}
		</>
	);
}
