import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import LeftSidebar from '../../../components/Meetings/LeftSideBar';
import { useMeeting, useUser } from '../../../store/hooks'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import MeetingNote from "../../../components/Meetings/MeetingNote";
import RightSidebar from "../../../components/Meetings/RightSidebar";

function MeetingsPage() {  
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const { selectedMeetingInfo, selectedChannelIndex, setSelectedChannelIndex, updateAvailableStatus } = useMeeting();
  const { user } = useUser();

  const updateAvailability = () => {
    console.log("update availability...")
    // console.log(selectedMeetingInfo)
    // console.log({ channel_id: selectedMeetingInfo?.channels[selectedChannelIndex]?.channel_id, user_id: user?.user?.user_id, availability: true })
    updateAvailableStatus({
      channel_id: selectedMeetingInfo?.channels[selectedChannelIndex]?.channel_id,
      user_id: user?.user?.user_id,
      availability: true
    })
  };

  useEffect(() => {
    const checkAvailabilityInterval = setInterval(updateAvailability, 10000);
    return () => {
      clearInterval(checkAvailabilityInterval)
    };
  }, [user, selectedMeetingInfo])

  useEffect(() => {
    return () => {
      setSelectedChannelIndex(-1)
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleGoMeeting = (e: any, id: any) => {
    e.preventDefault();
    history.push('/meeting/' + id);
  }

  return (
    <>
      <div className="flex flex-1">
        <LeftSidebar />
        <MeetingNote />
        <RightSidebar />
      </div>
    </>
  );
}

export default MeetingsPage;
