import './../../styles/styles.css'
import moment from 'moment-timezone';
import Rating from '@material-ui/lab/Rating';

const ReviewCard = (props: any) => {
  return (
    <>
      <div className="border-b-2 border-gray-200 mr-6 pb-3 px-4 mb-5">
        <div className="flex">
          <div className="w-full">
            <p className='font-bold text-base'>{props.review?.contract_title}</p>
            <p className="text-sm mt-2 whitespace-pre-line">{props.review?.review_feedback}</p>
            <p className="text-sm mt-2 flex mr-6">${props.review.rate} * {props.review.track_hours} hrs = ${props.review.rate * props.review.track_hours}</p>            
          </div>
          <div className="flex justify-around w-40">          
            { props.review.quality_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f917;</p>
                <p className="font-bold  text-xs">Quality</p>                
              </div>
            }
            { props.review.speed_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f604;</p>
                <p className="font-bold  text-xs">Speed</p>                
              </div>
            }
            { props.review.deadline_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f60D;</p>
                <p className="font-bold  text-xs">Deadline</p>
              </div>
            }
            { props.review.community_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f603;</p>
                <p className="font-bold  text-xs">Community</p>
              </div>
            }
            { props.review.design_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f970;</p>
                <p className="font-bold  text-xs">Design</p>
              </div>
            }
            { props.review.bad_flag &&   
              <div className="items-center text-center w-20">
                <p className="text-4xl mb-2">&#x1f621;</p>
                <p className="font-bold  text-xs">Bad</p>
              </div>
            }     
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;