import './../../styles/styles.css'
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import { useUser, useTransaction } from "../../store/hooks";
import ReviewEmoji from './Emoji';

const Review = (props: any) => {
  const { user } = useUser();
  const { selectedTransactionId, transactions } = useTransaction();

  return (
    <>
      {user.user.user_id !== props.item.user_id ?
        (<div className="flex flex-col items-end relative w-full mb-4" style={{ paddingRight: "45px" }}>
          <Avatar
            style={{ position: "absolute", top: '0', right: '0' }}
            src={process.env.REACT_APP_BASE_URL + user.user.avatar}
          >
            {user.user.first_name === "" ? null : (user.user.first_name.charAt(0).toUpperCase() + user.user.last_name.charAt(0).toUpperCase())}
          </Avatar>
          <div className="w-1/2 border-solid border-gray-300 border-2 px-4 py-2">
            <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">Review</p>
            {/* <div className="flex justify-center">
              <Rating name="size-large" value={Number(props.item.review_rating)} size="large" readOnly />
            </div> */}
            <div className="flex items-center">
              <div className="flex-2">Review Mark:</div>
              <div className="flex-1">
                <div className="flex justify-center">
                  {props.item.quality_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f917;</p>
                      <p className="font-bold  text-xs">Quality</p>
                    </div>
                  }
                  {props.item.speed_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f604;</p>
                      <p className="font-bold  text-xs">Speed</p>
                    </div>
                  }
                  {props.item.deadline_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f60D;</p>
                      <p className="font-bold  text-xs">Deadline</p>
                    </div>
                  }
                  {props.item.community_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f603;</p>
                      <p className="font-bold  text-xs">Community</p>
                    </div>
                  }
                  {props.item.design_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f970;</p>
                      <p className="font-bold  text-xs">Design</p>
                    </div>
                  }
                  {props.item.bad_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f621;</p>
                      <p className="font-bold  text-xs">Bad</p>
                    </div>
                  }
                </div>
              </div>
            </div>

            <p className="mb-1 text-gray-400 whitespace-pre-line">{props.item.review_feedback}</p>
          </div>
        </div>) :
        (<div className="flex flex-col items-start relative w-full mb-4" style={{ paddingLeft: "45px" }}>
          <Avatar
            style={{ position: "absolute", top: '0', left: '0' }}
            src={process.env.REACT_APP_BASE_URL + transactions[selectedTransactionId].avatar}
          >
            {transactions[selectedTransactionId].first_name === "" ? null : (transactions[selectedTransactionId].first_name.charAt(0).toUpperCase() + transactions[selectedTransactionId].last_name.charAt(0).toUpperCase())}
          </Avatar>
          <div className="w-1/2 border-solid border-gray-300 border-2 px-4 py-2">
            <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">Review</p>
            {/* <div className="flex justify-center">
              <Rating name="size-large" value={Number(props.item.review_rating)} size="large" readOnly />
            </div> */}
            <div className="flex items-center">
              <div className="flex-2">Review Mark:</div>
              <div className="flex-1">
                <div className="flex justify-center">
                  {props.item.quality_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f917;</p>
                      <p className="font-bold  text-xs">Quality</p>
                    </div>
                  }
                  {props.item.speed_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f604;</p>
                      <p className="font-bold  text-xs">Speed</p>
                    </div>
                  }
                  {props.item.deadline_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f60D;</p>
                      <p className="font-bold  text-xs">Deadline</p>
                    </div>
                  }
                  {props.item.community_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f603;</p>
                      <p className="font-bold  text-xs">Community</p>
                    </div>
                  }
                  {props.item.design_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f970;</p>
                      <p className="font-bold  text-xs">Design</p>
                    </div>
                  }
                  {props.item.bad_flag &&
                    <div className="items-center text-center w-20">
                      <p className="text-2xl mb-2">&#x1f621;</p>
                      <p className="font-bold  text-xs">Bad</p>
                    </div>
                  }
                </div>
              </div>
            </div>
            <p className="mb-1 text-gray-400 whitespace-pre-line">{props.item.review_feedback}</p>
          </div>
        </div>
        )
      }
    </>
  );
};

export default Review;