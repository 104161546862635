import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment/moment";
import Contract from "../Contract";
import Review from "../Review";
import Invoice from "../Contract/Invoice";
import TimeTrack from "../TimeTrack";
import { useState, useRef, useEffect } from "react";
import { useTransaction, useUser } from "../../store/hooks";
import TicketModal from "../Ticket-Modal";
import TrackModal from "../Track-Modal";
import ReviewModal from "../Review-Modal";
import SweetAlert from "react-bootstrap-sweetalert";
import ContractModal from '../../components/Contract-Modal';
import ChangeContractModal from "./ChangeContract";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { convertLocalToUTC } from "../../utils";

const useStyles = makeStyles((theme) => ({
  messageRow: {
    "&.contact": {
      "& .bubble": {
        backgroundColor: "rgb(226,232,240)",
        color: "black",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: "1px",
        wordBreak: "break-all",
        width: "fit-content",
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopLeftRadius: 10,
        },
      },
      "&.last-of-group": {
        marginBottom: "1.5rem",
        "& .bubble": {
          borderBottomLeftRadius: 10,
        },
      },
    },
    "&.me": {
      "& .avatar": {
        order: 2,
        margin: "0 0 0 16px",
      },
      "& .bubble": {
        marginLeft: "auto",
        backgroundColor: "rgb(49,130,206)",
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: "1px",
        wordBreak: "break-all",
        width: "fit-content",
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopRightRadius: 10,
        },
      },

      "&.last-of-group": {
        marginBottom: "1.5rem",
        "& .bubble": {
          borderBottomRightRadius: 10,
        },
      },
    },
    "&.first-of-group": {
      "& .bubble": {
        borderTopLeftRadius: 10,
      },
    },
    "&.last-of-group": {
      "& .bubble": {
        borderBottomLeftRadius: 10,
      },
    },
  },
}));

const TransactionMain = (props: any) => {
  const history = useHistory();
  const messageEl = useRef<HTMLDivElement>(null);
  const { sendMessageTr } = useTransaction();
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    transactions,
    transactionUnread,
    selectedTransactionId,
    changeContract,
  } = useTransaction();

  const { user } = useUser();
  const classes = useStyles(props);
  const [messageText, setMessageText] = useState("");
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showTrackModal, setShowTrackModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showChangeContractModal, setShowChangeContractModal] = useState(false);
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const onRequestSupport = () => {
    setShowSupportModal(true);
  };

  const shouldShowContactAvatar = (item: any, i: any) => {
    if (item.user_id !== user.user.user_id) return true;
    return false;
  };

  const onInputChange = (ev: any) => {
    setMessageText(ev.target.value);
  };

  const differceFromToday = (str: any) => {
    let date1 = new Date().setHours(0, 0, 0, 0);
    let date2 = new Date(str).setHours(0, 0, 0, 0);

    return (date1 - date2) / (24 * 60 * 60 * 1000);
  };
  const onMessageSubmit = (ev: any) => {
    ev.preventDefault();
    if (messageText === "" || messageText.trim() === "") {
      return;
    }

    sendMessageTr({
      user_id: user.user.user_id,
      msg_body: messageText,
      channel_id: transactions[selectedTransactionId].channel_id,
      // created_at: convertLocalToUTC(moment()),
      created_at: moment(),
      chat_type: "message",
    });

    setMessageText("");
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.ctrlKey) onMessageSubmit(e);
  };

  const onViewProfile = () => {
    if (user.user.user_role === "client")
      window.open("/profile/" + transactions[selectedTransactionId].fre_id);
    else if (user.user.user_role === "freelancer")
      window.open("/profile/" + transactions[selectedTransactionId].clt_id);
  };

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 8) {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
      var target: any = messageEl.current;

      if (target) {
        const scroll =
          messageEl.current.scrollHeight -
          messageEl.current.clientHeight;
        messageEl.current.scrollTo(0, scroll);
      }
      target.scroll({ top: target.scrollHeight, behavior: "smooth" });
    }
  };

  const onEndContract = async () => {

    confirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            (async () => {
              const result = await changeContract({
                status: "ended",
                id: transactions[selectedTransactionId].contract_id,
              });

              if (result) setShowReviewModal(true);
            })();
          }
        },
        {
          label: 'No',
          onClick: () => { return false; }
        }
      ]
    });

  };

  const createTrack = () => {
    setSelectedItem(null);
    setShowTrackModal(true);
  }

  useEffect(() => {
    if (messageEl.current !== null && selectedTransactionId !== -1) {
      var target: any = messageEl.current;
      if (target) target.scroll({ top: target.scrollHeight });
      messageEl.current.addEventListener("DOMNodeInserted", (event: any) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }

    if (selectedTransactionId !== -1) {
      if (transactions[selectedTransactionId].paid_first_invoice === false) {
        if (transactions[selectedTransactionId].has_pending_invoice === true) {
          if(user.user.user_role === "freelancer") {
            setAlertContent(
              "The client's funds are not on escrow yet. The client didn't pay your works now. Please wait until the client pay it."
            );
          } else {
            setAlertContent(
              "You didn't pay the pending invoices for this contract yet. Please pay it to keep the work."
            );
          }
          setPaymentAlert(true);
        } else {
          if(user.user.user_role === "freelancer") {
            setAlertContent("The client's funds are not on escrow yet.");
          } else {
            setAlertContent("You didn't pay the pending invoices yet.");
          }
          setPaymentAlert(true);
        }
      } else if (
        transactions[selectedTransactionId].has_pending_invoice === true
      ) {
        setAlertContent(
          "The client didn't pay your works now. Please wait until the client pay it."
        );
        setPaymentAlert(true);
      }
    }
  }, [selectedTransactionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={clsx(
        `justify-between flex-1 bg-white flex flex-col shadow-md relative`,
        props.className
      )}

    >
      {transactions.length !== 0 && selectedTransactionId !== -1 && (
        <>
          <div className="flex items-center justify-between h-16 px-3.5 border-b border-dp-gray">
            <div className="flex items-center">
              <Avatar
                onClick={onViewProfile}
                className="cursor-pointer"
                style={{ width: "3.3rem", height: "3.3rem" }}
                src={
                  process.env.REACT_APP_BASE_URL +
                  transactions[selectedTransactionId]?.avatar
                }
                alt={transactions[selectedTransactionId]?.full_name}
              >
                {transactions[selectedTransactionId]?.first_name === ""
                  ? null
                  : transactions[selectedTransactionId]?.first_name
                    .charAt(0)
                    .toUpperCase() +
                  transactions[selectedTransactionId]?.last_name
                    .charAt(0)
                    .toUpperCase()}
              </Avatar>
              <div>
                <p
                  className="ml-2 font-medium text-xl text-blue-600 cursor-pointer"
                  onClick={onViewProfile}
                >
                  {transactions[selectedTransactionId].full_name}
                </p>                
              </div>
            </div>
            <div className="flex space-x-1">
              {user.user.user_role === "client" &&
                transactions[selectedTransactionId].contract_status ===
                "accepted" ? (
                <>
                  <button
                    className="secondary-btn mx-1"
                    onClick={(e: any) => onEndContract()}
                  >
                    End Contract
                  </button>
                  <button
                    className="secondary-btn mx-1"
                    onClick={(e: any) => setShowChangeContractModal(true)}
                  >
                    Change Contract
                  </button>
                </>
              ) : user.user.user_role === "freelancer" &&
                transactions[selectedTransactionId].contract_status !==
                "ended" &&
                transactions[selectedTransactionId].contract_status ===
                "accepted" ? (
                <button
                  className="secondary-btn mx-1"
                  onClick={() => createTrack()}
                >
                  Track Time
                </button>
              ) : null}
              {transactions[selectedTransactionId].contract_status ===
                "ended" &&
                transactions[selectedTransactionId].has_review === false ? (
                <button
                  className="secondary-btn mx-1"
                  onClick={(e: any) => setShowReviewModal(true)}
                >
                  Send Review
                </button>
              ) : null}
              {transactions[selectedTransactionId].contract_status !==
                "ended" &&
                <button
                  onClick={onRequestSupport}
                  className="w-56 secondary-btn mx-1 tracking-wide"
                >
                  Request Support
                </button>
              }
              {transactions[selectedTransactionId].contract_status ===
                "ended" && user.user.user_role === 'client' &&
                <button
                  onClick={(e: any) => setShowContractModal(true)}
                  className="w-56 secondary-btn mx-1 tracking-wide"
                >
                  Send Offer
                </button>
              }
            </div>
          </div>
          <div className="relative flex-1">
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="h-full max-h-full p-6 mb-2 space-y-3.5 overflow-y-auto" ref={messageEl}>
                <div className="mt-auto"></div>
                <div className="max-w-screen-lg mx-auto w-full">
                  {/* <div className="text-center mx-16 mb-4">
                    <p className="text-black text-3xl">
                      Title:{" "}
                      {transactions[selectedTransactionId].job_title ||
                        transactions[selectedTransactionId].contract_title}
                    </p>
                    <p className="break-words whitespace-pre-line">
                      {transactions[selectedTransactionId].job_desc ||
                        transactions[selectedTransactionId].contract_desc}
                    </p>
                  </div> */}
                  {transactions[selectedTransactionId].history.map(
                    (item: any, i: any) => {
                      return (
                        <div key={i}>
                          {i === 0 ||
                            (i > 0 &&
                              differceFromToday(item.datetime? item.datetime: item.created_at) !==
                              differceFromToday(
                                transactions[selectedTransactionId].history[
                                  i - 1
                                ].datetime? transactions[selectedTransactionId].history[
                                  i - 1
                                ].datetime: transactions[selectedTransactionId].history[
                                  i - 1
                                ].created_at 
                              )) ? (
                            <div
                              className="w-full flex items-center my-4"
                              style={{
                                paddingLeft: "45px",
                                paddingRight: "45px",
                              }}
                            >
                              <div
                                className="w-full bg-gray-300"
                                style={{ height: "1px" }}
                              ></div>
                              <div className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                {differceFromToday(item.datetime? item.datetime: item.created_at) === 0
                                  ? "Today"
                                  : differceFromToday(item.datetime? item.datetime: item.created_at) === 1
                                    ? "Yesterday"
                                    : differceFromToday(item.datetime? item.datetime: item.created_at) < 4
                                      ? moment(new Date(item.datetime? item.datetime: item.created_at)).format(
                                        "dddd"
                                      )
                                      : moment(new Date(item.datetime? item.datetime: item.created_at)).format(
                                        "dddd, MMMM D, YYYY"
                                      )}
                              </div>
                              <div
                                className="w-full bg-gray-300"
                                style={{ height: "1px" }}
                              ></div>
                            </div>
                          ) : null}

                          {transactionUnread !== 0 &&
                            transactions[selectedTransactionId].history.length -
                            i ===
                            transactionUnread ? (
                            <div
                              className="w-full flex items-center my-4"
                              style={{ paddingLeft: "50px" }}
                            >
                              <div
                                className="w-full bg-blue-500"
                                style={{ height: "2px" }}
                              ></div>
                              <div className="px-3 text-sm text-blue-500 whitespace-nowrap">
                                Unread Messages
                              </div>
                              <div
                                className="w-full bg-blue-500"
                                style={{ height: "2px" }}
                              ></div>
                            </div>
                          ) : null}

                          {item.chat_type === "message" ||
                            item.chat_type === "m_chat" ? (
                            <div
                              className={clsx(
                                classes.messageRow,
                                "flex flex-grow-0 flex-shrink-0 items-start relative",
                                { me: item.user_id === user.user.user_id },
                                { contact: item.user_id !== user.user.user_id },
                                "pb-2"
                              )}
                            >
                              {shouldShowContactAvatar(item, i) ? (
                                <div className="mb-2 flex">
                                  <Avatar
                                    className="avatar absolute ltr:left-0 rtl:right-0 m-0"
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      transactions[selectedTransactionId].avatar
                                    }
                                  >
                                    {transactions[selectedTransactionId]
                                      .first_name === ""
                                      ? null
                                      : transactions[
                                        selectedTransactionId
                                      ].first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      transactions[
                                        selectedTransactionId
                                      ].last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Avatar>
                                  <div style={{ marginLeft: "5px" }}>
                                    <div className="bubble flex relative items-center justify-center px-4 py-3 max-w-full mr-auto shadow-1">
                                      <div className="leading-tight whitespace-pre-wrap">
                                        {item.msg_body}
                                      </div>
                                    </div>
                                    <p className="time w-full text-xs ltr:left-0 rtl:right-0 bottom-0 whitespace-no-wrap mb-1">
                                      {moment(new Date(item.datetime? item.datetime: item.created_at)).format(
                                        "h:mm A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="flex flex-col items-end relative w-full mb-2"
                                  style={{ paddingRight: "45px" }}
                                >
                                  <Avatar
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                    className="avatar absolute ltr:left-0 rtl:right-0 m-0"
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      user.user.avatar
                                    }
                                  >
                                    {user.user.first_name === ""
                                      ? null
                                      : user.user.first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      user.user.last_name
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Avatar>
                                  <div
                                    className="ml-auto bubble flex relative items-center justify-center px-4 py-3 shadow-1"
                                    style={{ width: "fit-content" }}
                                  >
                                    <div className="leading-tight whitespace-pre-wrap">
                                      {item.msg_body}
                                    </div>
                                  </div>
                                  <p className="time w-full text-xs ltr:left-0 rtl:right-0 bottom-0 whitespace-no-wrap mb-1 flex justify-end">
                                    {moment(new Date(item.datetime? item.datetime: item.created_at)).format(
                                      "h:mm A"
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : item.chat_type.includes("contract_") ? (
                            <Contract item={item} index={i} key={`contract_${i}`} />
                          ) : item.chat_type === "timetrack" ? (
                            <TimeTrack item={item} index={i} key={`timetrack_${i}`} setSelectedItem={setSelectedItem} setShowTrackModal={setShowTrackModal} />
                          ) : item.chat_type === "review" ? (
                            <Review item={item} index={i} key={`review_${i}`} />
                          ) : item.chat_type === "invoice_sent" || item.chat_type === "invoice_paid" ? (
                            <Invoice item={item} index={i} key={`invoice_${i}`} />
                          ) : null}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mx-4 mt-4 rounded-lg bg-dp-gray-very-light text-dp-gray-very-dark">
            <div className="flex items-center justify-between px-3 py-4">
              <textarea
                className="resize-none overflow-hidden  block border-0 px-0 placeholder-dp-gray-dark py-0 focus:ring-0 w-full text-sm bg-transparent focus:outline-none break-word"
                placeholder="Type your Message"
                onKeyDown={(e: any) => onKeyDown(e)}
                onKeyUp={(e: any) => onKeyUp(e)}
                onChange={onInputChange}
                value={messageText}
                rows={1}
              ></textarea>
              <button
                onClick={onMessageSubmit}
                className={`focus:outline-none`}
                disabled={messageText === ""}
              >
                <svg
                  className={clsx(
                    `transition w-5 h-5`,
                    messageText === "" ? "text-dp-blue" : "text-dp-blue-dark"
                  )}
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M1.393 20.001a1.347 1.347 0 01-1.241-1.963c.848-1.719 1.742-3.416 2.555-5.151a2.654 2.654 0 011.916-1.531C5.8 11.067 7 10.906 8.2 10.734c.7-.1 1.4-.182 2.1-.278a.967.967 0 00.323-.1.382.382 0 00.208-.416.419.419 0 00-.365-.363c-.378-.061-.759-.106-1.139-.16a44.026 44.026 0 01-4.714-.785 3.207 3.207 0 01-1.128-.469 1.807 1.807 0 01-.56-.648C2 5.667 1.063 3.815.147 1.955A1.343 1.343 0 011.166.015a1.428 1.428 0 01.881.176q4.861 2.433 9.725 4.862 3.682 1.841 7.365 3.679A1.361 1.361 0 0120 9.915a1.341 1.341 0 01-.846 1.347q-2.646 1.313-5.285 2.641l-11.81 5.898a1.436 1.436 0 01-.666.2"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex justify-end max-w-screen-lg mx-auto w-full  mb-3">
            <p className="text-sm">
              Press <i>Ctrl+Enter</i> to send a message.
            </p>
          </div>
        </>
      )}
      {showSupportModal ? (
        <TicketModal setShowModal={setShowSupportModal}></TicketModal>
      ) : null}
      {showTrackModal ? (
        <TrackModal setShowModal={setShowTrackModal} item={selectedItem}></TrackModal>
      ) : null}
      {showReviewModal ? (
        <ReviewModal setShowModal={setShowReviewModal}></ReviewModal>
      ) : null}
      {showChangeContractModal ? (
        <ChangeContractModal setShowModal={setShowChangeContractModal}></ChangeContractModal>
      ) : null}
      {showContractModal ? <ContractModal setShowModal={setShowContractModal} resend={true}></ContractModal> : null}
      <SweetAlert
        title=""
        show={paymentAlert}
        type="warning"
        onConfirm={(response) => setPaymentAlert(false)}
      >
        {alertContent}
      </SweetAlert>
    </div>
  );
};

export default TransactionMain;
