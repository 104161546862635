import MeetingMembers from './MeetingMembers';
import MeetingInfo from './MeetingInfo';
export default function RightSidebar() {

	return (
		<div className="bg-white w-250 border-l border-dp-gray p-2">
			<div className="flex flex-col justify-between h-full">
				<h2 className="text-dp-blue-very-dark font-semibold leading-tight blocktext-left p-2">Chat Rooms</h2>
				<div className="flex flex-col flex-auto">
					<MeetingMembers />
				</div>
				<h2 className="text-dp-blue-very-dark font-semibold leading-tight blocktext-left p-2">Meeting Info</h2>
				<div className="flex flex-col flex-auto">
					<MeetingInfo />
				</div>
			</div>
		</div>
	);
}
