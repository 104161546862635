import './../../styles/styles.css'
import { useEffect, useState } from "react";
import moment from "moment";
import { useTransaction } from "../../store/hooks";
import { convertLocalToUTC, convertUTCToLocal } from '../../utils';
import { deflateSync } from 'zlib';

const TrackModal = (props: any) => {
  const [timezone, setTimezone] = useState(props?.item?.trk_timezone ?? "utc");
  const [startTime, setStartTime] = useState(props?.item?.trk_from ?? "00:00:00");
  const [endTime, setEndTime] = useState(props?.item?.trk_to ?? "00:00:00");
  const [date, setDate] = useState(moment(props?.item?.trk_date ?? null).format("YYYY-MM-DD"));
  const [description, setDescription] = useState(props?.item?.trk_description ?? '');
  const [hours, setHours] = useState(props?.item?.trk_total_hrs ?? '0');
  const [hoursError, setHoursError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const { selectedTransactionId, transactions, sendMessageTr, removeTimeTrack } = useTransaction();

  useEffect(() => {
    if (props.item) {
      setTimezone(props.item.trk_timezone ?? "utc")
      if (props.item.trk_timezone == 'local') {
        //convert utc to local
        let start_date = convertUTCToLocal(props.item.trk_date + ' ' + props.item.trk_from);
        setStartTime(moment(start_date).format('HH:mm:ss'));
        let end_date = convertUTCToLocal(props.item.trk_date + ' ' + props.item.trk_to);
        setEndTime(moment(end_date).format('HH:mm:ss'));
        setDate(moment(start_date).format('YYYY-MM-DD'));
      } else {
        setStartTime(props.item.trk_from ?? "00:00:00")
        setEndTime(props.item.trk_to ?? "00:00:00")
        setDate(moment(props.item.trk_date ?? null).format("YYYY-MM-DD"));
      }
      setDescription(props.item.trk_description ?? '')
      setHours(props.item.trk_total_hrs ?? '0')
    }
  }, [props])

  const handleSubmit = async () => {
    if (props?.item) {
      await removeTimeTrack({
        track_obj_id: props?.item.trk_id,
      });
      props.setShowModal(false);
    } else {
      if (description === '') {
        setDescriptionError('Please input track description a week.');
        return;
      } else {
        setDescriptionError('');
      }

      if (parseFloat(hours) <= 0) {
        setHoursError('The hours should greater than 0');
        return;
      } else {
        setHoursError('');
      }
      //need to convert local to utc when select local.
      let trk_from = startTime;
      let trk_to = endTime;
      
      let trk_date = date;
      let trk_date_to = date;
      if(!trk_date || trk_date == 'Invalid date') {
        trk_date = moment().format("YYYY-MM-DD");
      }
      if (timezone == 'local') {
        console.log("=======================")
        let start_date = convertLocalToUTC(trk_date+' '+startTime)
        let end_date = convertLocalToUTC(trk_date+' '+endTime)
        trk_from = moment(start_date).format('HH:mm:ss');
        trk_to = moment(end_date).format('HH:mm:ss');
        trk_date = moment(start_date).format('YYYY-MM-DD');
        trk_date_to = moment(end_date).format('YYYY-MM-DD');
        console.log("trk_date: ", trk_date)
        console.log("trk_date_to: ", trk_date_to)
      }
      // if(moment(trk_date+' '+trk_from).isAfter(moment(trk_date+' '+trk_to))){
      console.log("TEST: ", moment(trk_date+' '+trk_from).isSame(moment(trk_date_to+' '+trk_to)));
      if (!moment(trk_date+' '+trk_from).isSame(moment(trk_date_to+' '+trk_to))) {
        if (moment(trk_date_to+' '+trk_to).format('HH:mm:ss') !== '00:00:00') {
          console.log("111")
          let first_hour = moment(trk_date+' 00:00:00').add(1, 'day').diff(moment(trk_date+' '+trk_from), 'hour', true);
          let second_hour = moment(trk_date+' '+trk_to).diff(moment(trk_date+' 00:00:00'), 'hour', true);
          
          await sendMessageTr({
            channel_id: transactions[selectedTransactionId].channel_id,
            chat_type: 'timetrack',
            trk_timezone: timezone,
            timetracks: [
              {
                trk_total_hrs: parseFloat(first_hour.toFixed(1)),
                trk_from: trk_from,
                trk_to: '23:59:59',
                trk_date: trk_date
              },
              {
                trk_total_hrs: parseFloat(second_hour.toFixed(1)),
                trk_from: '00:00:00',
                trk_to: trk_to,
                trk_date: moment(trk_date).add(1, 'day').format('YYYY-MM-DD')
              }
            ],
            trk_total_hrs: hours,
            trk_description: description,
            created_at: new Date(),
          })
        }
        else {
          console.log("222")
          await sendMessageTr({
            channel_id: transactions[selectedTransactionId].channel_id,
            chat_type: 'timetrack',
            trk_timezone: timezone,
            trk_total_hrs: hours,
            trk_from: trk_from,
            trk_to: trk_to,
            trk_date: trk_date,
            trk_description: description,
            created_at: new Date(),
          })
        }
      } else {
        console.log("333")
        await sendMessageTr({
          channel_id: transactions[selectedTransactionId].channel_id,
          chat_type: 'timetrack',
          trk_timezone: timezone,
          trk_total_hrs: hours,
          trk_from: trk_from,
          trk_to: trk_to,
          trk_date: trk_date,
          trk_description: description,
          created_at: new Date(),
        })
      }
      
      props.setShowModal(false);
    }
  }

  return (
    <>
      <div
        className="justify-center items-center flex w-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative my-6 mx-auto" style={{ width: "500px" }}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-bold">
                {props?.item ? "Remove Time Track" : "Track Time"}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.setShowModal(false)}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>


            {/*body*/}
            <div className="relative px-6 py-2 flex-auto w-full">
              <div className="mt-4 w-full flex items-center">
                <p className="w-32">Timezone:</p>
                <select
                  value={timezone}
                  onChange={(e: any) => setTimezone(e.target.value)}
                  disabled={props?.item}
                  className="block ml-2 px-8 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2">
                  <option value={'utc'}>UTC</option>
                  <option value={'local'}>Local</option>
                </select>
              </div>
              <div className="mt-4 w-full flex items-center">
                <p className="w-32">Track Date:</p>
                <input
                  value={date}
                  onChange={(e: any) => setDate(e.target.value)}
                  type="date"
                  placeholder="Date"
                  readOnly={props?.item}
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  className="block ml-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2" />
              </div>

              <div className="mt-2 w-full flex items-center">
                <p>From: </p>
                <input
                  value={startTime}
                  onChange={(e: any) => {
                    setStartTime(e.target.value);
                    console.log(e.target.value);
                    let val: any = (new Date("04/09/2013 " + endTime).getTime() - new Date("04/09/2013 " + e.target.value).getTime()) / 3600000;
                    val = parseFloat(val.toFixed(1));
                    setHours(val);
                  }}
                  readOnly={props?.item}
                  type="time"
                  className="mx-3 block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2" />
                <p>To: </p>
                <input
                  value={endTime}
                  onChange={(e: any) => {
                    setEndTime(e.target.value);
                    let val: any = (new Date("04/09/2013 " + e.target.value).getTime() - new Date("04/09/2013 " + startTime).getTime()) / 3600000;
                    val = parseFloat(val.toFixed(1));
                    setHours(val);
                  }}
                  readOnly={props?.item}
                  type="time"
                  className="block ml-3 w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2" />
              </div>

              <div className="mt-2 w-full flex items-center">
                <p className="w-32">Track Hours:</p>
                <input
                  value={hours}
                  autoComplete="off"
                  type="text"
                  disabled
                  className="block ml-2 w-20 text-center px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2 ">
                </input>
              </div>
              {hoursError && <p className="text-left text-xs text-red-500 mt-1">{hoursError}</p>}

              <div className="mt-2 w-full">
                <textarea
                  className={"resize-y border w-full px-4 py-2 focus:outline-none rounded-md overflow-hidden h-40 " + (descriptionError !== '' ? 'border-red-500' : '')}
                  autoComplete="off"
                  placeholder="Description"
                  readOnly={props?.item}
                  onChange={e => setDescription(e.target.value)}
                  onKeyDown={e => setDescriptionError('')}
                  value={description}
                ></textarea>
                {descriptionError && <p className="text-left text-xs text-red-500 mt-1">{descriptionError}</p>}
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Close
              </button>
              <button onClick={handleSubmit} className="ml-3 px-4 py-2 secondary-btn">{props?.item ? "Remove" : "Confirm"}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default TrackModal;
