import { useEffect } from 'react';
import { useUser, useTransaction, useMeeting } from "../../../store/hooks";
import LeftSidebar from '../../../components/Transactions/LeftSideBar';
import TransactionMain from '../../../components/Transactions/TransactionMain';
import RightSidebar from '../../../components/Transactions/RightSidebar';
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import Pusher from 'pusher-js'

const TransactionPage = (props: any) => {
  const { getTransactions, setSelectedTransactionId, selectedTransactionId } = useTransaction();
  const { setUnreadTransaction } = useUser();

  useEffect(() => {
    getTransactions();
    setUnreadTransaction(0);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps  

  useEffect(() => {
    return () => {
      setSelectedTransactionId(-1)
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="flex flex-1">
        <LeftSidebar />
        <TransactionMain />
        <RightSidebar />
      </div>
    </>
  );
  
}

export default TransactionPage;