import { useRef, useState, useEffect } from "react";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import isEmail from '../../../utils/validation/isEmail';
import { usePostJob, useUser } from "../../../store/hooks";
import { useNotification } from '../../../store/hooks/notification.hook';
import { NOTI_TYPE } from "../../../store/types";
import { Button } from "@material-ui/core";
import { AuthService } from "../../../services";

import avtar1 from "../../../images/avtar1.png";
import avtar2 from "../../../images/avtar2.png";
import avtar3 from "../../../images/avtar3.png";
import avtar4 from "../../../images/avtar4.png";
import avtar5 from "../../../images/avtar5.png";
import avtar6 from "../../../images/avtar6.png";
import cameraIcon from "../../../images/cameraIcon.png";
import { UserService } from "../../../services";

const useStyles = makeStyles(theme => ({
  postInput: {
    padding: "15px",
    color: "#4a5568",
    borderRadius: "5px",
    border: "1px solid #c4c4c4",
    "&:hover": {
      border: "1px solid #212121"
    },
    "&:focus": {
      outline: 0,
      border: "2px solid #3f51b5"
    }
  },
  error: {
    border: "1px solid red",
  },
  imageDiv: {
    position: "relative"
  },
  cameraIcon: {
    position: "absolute",
    bottom: "9%",
    right: "34%",
    width: "27px",
    height: "27px"
  },
  selectedAvtar: {
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    overflow: "hidden",
    justifyContent: "center",
    display: "flex",
    margin: "auto",
  }
}));

function SelectMeetingPage(props: any) {
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [confirmCode, setConfirmCode] = useState('');
  const [processStep, setProcessStep] = useState(1);
  const [formData, setFormData] = useState({ name: "", password: "", cPassword: "", avtar: 'https://api.asiangurus.com/img/avatars/avtar1.png' });
  const [formerror, setFormerror] = useState({ name: "", password: "", cPassword: "", avtar: "" });
  const { getConfirmCode, addJobInfo } = usePostJob();
  const { userSignup, user } = useUser();
  const classes = useStyles(props);
  const { setNewNotification } = useNotification();

  const openImageSelection: any = useRef(null)
  const [avatars, setAvatars] = useState([]);
  const handleGoMeeting = () => {
    history.push('/meetings');
  }

  const handlePostAnother = () => {
    history.push('/client/post-job');
  }

  useEffect(() => {
    _get_AvailableAvatars();
  }, []);

  const _get_AvailableAvatars = () => {
    UserService.getAvailableAvatars().then(res => {
      console.log(res.data);
      let avatars_list = [];
      for (const [key, value] of Object.entries(res.data)) {
        avatars_list.push('https://api.asiangurus.com/img/avatars/' + value);
      }
      setAvatars(avatars_list);
      setFormData({...formData, avtar:avatars_list[0]});      
    });
  };


  const handleSendVerification = async (e: any) => {
    e.preventDefault();
    if (email === '') {
      setEmailError('Please input email.');
      return;
    }

    if (!isEmail(email)) {
      setEmailError('Please input correct email.');
      return;
    }

    setEmailError('');
    setDisabled(false);
    let result: any = await getConfirmCode(email);
    setConfirmCode(result);
  }

  const handleConfirm = async (e: any) => {
    e.preventDefault();
    if (code === '') {
      setCodeError('Please input confirm code.');
      return;
    }

    if (confirmCode == code) {
      setProcessStep(2);
    }
    else {
      setCodeError('Wrong confirm code.');
      return;
    }
  }

  const handleRegisterSubmit = async () => {
    if (!email.length) {
      setNewNotification(NOTI_TYPE.WARNING, "Please enter email");
      return
    }

    if (!formData?.name.length) {
      setFormerror({ ...formerror, name: "Please enter name" })
    } else if (!formData?.password.length) {
      setFormerror({ ...formerror, password: "Please enter password" })
    } else if (!formData?.cPassword) {
      setFormerror({ ...formerror, cPassword: "Please enter confirm password" })
    } else if (formData?.password !== formData?.cPassword) {
      setFormerror({ ...formerror, cPassword: "Password does not match" })
    } else {
      const result = await userSignup({
        email: email, 
        user_role: 'client',
        alias: formData?.name,
        avatar: formData?.avtar.replace("https://api.asiangurus.com", ""),
        password: formData?.password
      });

      if (result)
        await addJobInfo();
    }
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target.name;
    const value = event?.target.value;
    console.log("name => ", name, "value", value);

    setFormData({ ...formData, [name]: value })
  }

  const handleFormError = (property: string) => {
    setFormerror({ ...formerror, [property]: '' })
  }

  const googleLogin = async () => {
    let url = await AuthService.loginGoogle(1);
    window.location.href = url.data;
  }

  return (
    <div className="h-full overflow-auto bg-gray-200">
      {user.token !== '' && (<div className="mx-auto mt-10 p-6 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 w-96 text-center">
        <p className="text-black mb-6">Thank you to choose us</p>
        <p className="text-black mb-6">Meeting is scheduled. You can find on meetings page.</p>
        <div>
          <button onClick={handleGoMeeting} className="secondary-btn">
            Go to the Meeting
          </button>
        </div>
        <div className="mt-4">
          <button onClick={handlePostAnother} className="secondary-btn">
            Post Another Project
          </button>
        </div>
      </div>)}

      {user.token === '' && processStep == 1 && <div className="mx-auto mt-10 p-6 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 w-96">
        <div className="flex justify-center mt-2">
          <a className="flex" href="/home">
            <img src="../logo.png" className="h-12 w-12" alt="Logo"></img>
            <div className="logo-title">ASIANGURUS</div>
          </a>
        </div>
        <Button onClick={googleLogin} className="w-full flex items-center justify-center mt-4 text-gray-600 rounded-lg shadow-md dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
          <div className="px-4 py-3">
            <svg className="w-6 h-6" viewBox="0 0 40 40">
              <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107" />
              <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00" />
              <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50" />
              <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2" />
            </svg>
          </div>
          <span className="w-5/6 px-4 py-3 font-bold text-center">Sign up with Google</span>
        </Button>
        <p className="text-center text-gray-700 dark:text-white mt-6">Please enter your email to setup the schedule</p>
        <div className="mt-10">
          <input id="emailAddress" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} className={"block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring " + (emailError ? 'border-red-500' : 'border-grey-300')} type="email"></input>
          {emailError && <p className="text-left text-xs text-red-500 mt-1">{emailError}</p>}
        </div>
        <div className="mt-2">
          <button onClick={handleSendVerification} className="w-full secondary-btn">
            Send Verification Code
          </button>
        </div>
        <div className="mt-10">
          <input id="loggingPassword" placeholder="12345" value={code} onChange={e => setCode(e.target.value)} className={"disabled:opacity-50 block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring " + (codeError ? 'border-red-500' : 'border-grey-300')} type="text" disabled={disabled}></input>
          {codeError && <p className="text-left text-xs text-red-500 mt-1">{codeError}</p>}
        </div>

        <div className="mt-2">
          <button onClick={(e: any) => handleConfirm(e)} className={"w-full secondary-btn " + (disabled ? "disabled" : "")} disabled={disabled}>
            Confirm Code
          </button>
        </div>
      </div>}
      {user.token === '' && processStep == 2 && <div className="mx-auto mt-10 p-6 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 w-96">
        <div className="text-2xl text-black wow fadeInUp mt-6 mb-2 font-bold" data-wow-delay="1s">Setting</div>
        <div className="mt-7">
          <div className="mt-4">
            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="UserNickName">User Nickname</label>
            <input
              id="name"
              value={formData?.name}
              name="name"
              placeholder="Enter Your Nick Name"
              autoComplete="off"
              onChange={e => handleFormChange(e)}
              onKeyDown={() => handleFormError('name')}
              className={classes.postInput + " block w-full " + (formerror?.name ? classes.error : '')} type="text">
            </input>
            {formerror?.name && <p className="text-left text-xs text-red-500 mt-1">{formerror?.name}</p>}
          </div>

          <div className="mt-4">
            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="UserPassword">User Password</label>
            <input
              id="password"
              type="password"
              value={formData?.password}
              name="password"
              placeholder="New Password"
              onChange={e => handleFormChange(e)}
              onKeyDown={() => handleFormError('password')}
              className={classes.postInput + " block w-full " + (formerror?.password ? classes.error : '')}>
            </input>
            {formerror?.password && <p className="text-left text-xs text-red-500 mt-1">{formerror?.password}</p>}
          </div>

          <div className="mt-4">
            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="ConfirmPassword">Confirm Password</label>
            <input
              id="cPassword"
              type="password"
              value={formData?.cPassword}
              name="cPassword"
              placeholder="Confirm Password"
              onChange={e => handleFormChange(e)}
              onKeyDown={() => handleFormError('cPassword')}
              className={classes.postInput + " block w-full " + (formerror?.cPassword ? classes.error : '')}>
            </input>
            {formerror?.cPassword && <p className="text-left text-xs text-red-500 mt-1">{formerror?.cPassword}</p>}
          </div>

          <div className="mt-4">
            <p className="wow text-center fadeInUp mt-6 mb-4 font-bold">Select Your Avatar</p>
            <div className={clsx(classes.imageDiv, "mb-4")}>
              <img className={classes.selectedAvtar} src={formData?.avtar} alt="preview image" />              
            </div>            
            <div style={{ display: "flex" }}>
            {avatars.map((avatar_uri: any, i: any) => (
              <div onClick={(e: any) => setFormData({ ...formData, avtar: avatar_uri })} key={i}><img src={avatar_uri} alt="Avtar1" /></div>              
            ))}
            </div>
          </div>

          <div className="mt-4">
            <button onClick={handleRegisterSubmit} className="secondary-btn next-btn mt-5 w-full">
              Register
            </button>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default SelectMeetingPage;
