import { useState, useEffect } from "react";
import { useUser, useTransaction } from "../../../store/hooks";
import DataTable from 'react-data-table-component';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { convertUTCToLocal } from '../../../utils';
import moment from "moment-timezone";

function SummaryPage(props: any) {
    const { user } = useUser();
    const { getSummary } = useTransaction();
    const [summary, setSummary] = useState([]);
    const [dateRange, onChangeDateRange] = useState([new Date(), new Date()]);
    useEffect(() => {
        (async () => {
            if(dateRange !== null) {
                const result = await getSummary(moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD'));
                setSummary(result?.data?.data);
            }
        })();
    }, [dateRange]);
    const columns = [
        {
            name: 'Title',
            selector: row => row.contract_title,
            sortable: true,
            width: '20%'
        },
        {
            name: 'Description',
            selector: row => row.contract_desc,
            sortable: true,
            width: '24%'
        },
        {
            name: user.user.user_role == 'freelancer' ? 'Client': 'Developer',
            selector: row => row.full_name,
            sortable: true,
            width: '14%'
        },
        {
            name: 'Rate($/h)',
            selector: row => row.contract_hourly_rate,
            sortable: true,
            width: '10%'
        },
        {
            name: 'Hours',
            selector: row => row.total_hrs ?? '0',
            sortable: true,
            width: '10%'
        },
        {
            name: user.user.user_role == 'freelancer' ? 'Earned($)': 'Spent($)',
            // selector: row => "$" + (row.total_spent ?? "0.00"),
            selector: row => {
                const totalSpent = parseFloat(row.total_spent);
                return isNaN(totalSpent) ? "$0.00" : "$" + totalSpent.toFixed(2);
            },
            sortable: true,
            width: '11%'
        },
        {
            name: 'Status',
            selector: row => row.contract_status,
            sortable: true,
            width: '11%'
        },
    ];


    return (
        <div className="h-full bg-gray-100">
            <div className="md:flex container mt-2 w-full">
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 w-full">
                    <div className="px-4 sm:p-6 relative transaction_container">
                        <div className="-mx-4 flex  sm:-mx-6 md:mx-0 r-0 transaction-date-range">
                            <div className="flex-col font-sm">
                                Date: &nbsp;
                            </div>
                            <div className="flex-col">
                                <DateRangePicker onChange={onChangeDateRange} value={dateRange} />
                            </div>
                        </div>
                        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                            <DataTable
                                title="Transaction Summary"
                                customStyles={{ 
                                    tableWrapper: { 
                                        style: {
                                            display: 'table', 
                                            tableLayout: 'fixed',
                                        }
                                    }
                                }}
                                columns={columns}
                                data={summary}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SummaryPage;
