import moment from "moment-timezone";

export const convertUTCToLocal = (dt: any) => {
    var toDt = moment.utc(dt).toDate();
    return moment(toDt);
}

export const convertLocalToUTC = (dt: any) => {
    return moment(dt).utc().format('YYYY-MM-DD HH:mm:ss');
}

export const convertLocalToEST = (dt: any) => {
    return moment(dt).tz('America/New_York').format('YYYY-MM-DD HH:mm');
}

export const convertESTToLocal = (dt: any) => {
    let toDt = moment.tz(dt, 'America/New_York').toDate();
    return moment(toDt).format('YYYY-MM-DD HH:mm');
}
