import { useEffect, useState } from "react";
import { useTransaction } from "../../store/hooks";
import TransactionListItem from "./TransactionListItem";
import SidebarTitle from '../Common/SdiebarTitle';
import { TRANSACTION_FILTER } from "../../consts";
import { convertUTCToLocal  } from '../../utils';
import Contract from "../ChatBox/Contract";

const LeftSidebar = (props: any) => {
  const { transactions } = useTransaction();
  const [ searchValue, setSearchValue ] = useState("");
  const [ filter, setFilter] = useState(TRANSACTION_FILTER.ALL);

  const [filteredList, setFilteredList] = useState<any>([]);

  useEffect(() => {
    console.log('transaction', transactions);
    let newList: any[] = [];
    transactions.map((contact: any, i: any) =>{
      if(filter == TRANSACTION_FILTER.ALL){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase())){
            newList.push({...contact,index:i});
        }
      }

      if(filter == TRANSACTION_FILTER.IN_PROGRESS){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase()) && (contact.contract_status == "accepted" || contact.contract_status == "pending")){
            newList.push({...contact,index:i});
        }
      }
      
      if(filter == TRANSACTION_FILTER.CLOSED){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase()) && contact.contract_status == "ended"){
            newList.push({...contact,index:i});
        }
      }
    });
    
    setFilteredList(newList);
  },[transactions, searchValue, filter])

  const getFilteredList = (transactions, searchValue, filter) => {
    let newList: any[] = [];
    transactions.map((contact: any, i: any) =>{
      if(filter == TRANSACTION_FILTER.ALL){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase())){
            newList.push({...contact,index:i});
        }
      }

      if(filter == TRANSACTION_FILTER.IN_PROGRESS){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase()) && (contact.contract_status == "accepted" || contact.contract_status == "pending")){
            newList.push({...contact,index:i});
        }
      }
      
      if(filter == TRANSACTION_FILTER.CLOSED){
        if(contact.contract_title
          .toLowerCase()
          .includes(searchValue.toLowerCase()) && contact.contract_status == "ended"){
            newList.push({...contact,index:i});
        }
      }
    });

    return newList
  }
  return (

		<div className="px-3.5 pt-2.5 relative">
			<div className="flex flex-col justify-between h-full w-300">
				<div className="flex flex-col flex-1 space-y-3">
          <SidebarTitle
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
            onClose={() => setSearchValue('')}
            title="Transactions"
            hasSearch={true}
            placeholder="Search transactions..."
          />
          <div className="inline-flex shadow-sm rounded-md mx-auto" role="group">
            <button 
              onClick={(e) => setFilter(TRANSACTION_FILTER.ALL)}
              type="button" className={`${filter == TRANSACTION_FILTER.ALL ? "bg-gray-200" : "bg-white"} rounded-l-lg border border-gray-200 text-xs font-xs px-4 py-1 text-gray-900 hover:bg-gray-100`}>
              ALL
            </button>
            <button 
              onClick={() => setFilter(TRANSACTION_FILTER.IN_PROGRESS)}
              type="button" className={`${filter == TRANSACTION_FILTER.IN_PROGRESS ? "bg-gray-200" : "bg-white"} border-t border-b border-gray-200 text-xs font-medium px-4 py-1 text-gray-900 hover:bg-gray-100`}>
              IN_PROGRESS
            </button>
            <button 
              onClick={() => setFilter(TRANSACTION_FILTER.CLOSED)}
              type="button" className={`${filter == TRANSACTION_FILTER.CLOSED ? "bg-gray-200" : "bg-white"} rounded-r-md border border-gray-200 text-xs font-medium px-4 py-1 text-gray-900 hover:bg-gray-100`}>
              CLOSED
            </button>
          </div>
          <div className={`opacity-100 transition flex flex-1 flex-col space-y-2.5 mb-2`}>
            <div className="relative flex-1">
              <div className="absolute top-0 left-0 w-full meeting-side ">
                <div className="flex flex-col max-h-full mb-2 space-y-2.5 overflow-y-auto">
                  {getFilteredList(transactions, searchValue, filter).map((contact: any, i: any) =>
                    <TransactionListItem key={i} contact={contact} index={contact?.index} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
