import Separator from '../Common/Separator';
import MeetingThreadSkeleton from './MeetingThreadSkeleton';

export default function LoadingCustomerThreads() {
	return (
		<div className="flex flex-1 flex-col space-y-2">
			<Separator />
			<div className="relative flex-1">
				<div className="absolute top-0 left-0 w-full h-full">
					<div className="flex flex-col max-h-full mb-2 space-y-2 overflow-y-auto">
						<MeetingThreadSkeleton />
						<MeetingThreadSkeleton />
						<MeetingThreadSkeleton />
						<Separator />
					</div>
				</div>
			</div>
		</div>
	);
}
