import { LOCAL_STORAGE_KEY } from "../../consts";
import { USER, MEETING, TRANSACTION, POSTJOB } from "../types";
import { UserService, MeetingService, TransactionService } from "../../services";
const { JWT_TOKEN, USER_DATA, POST_JOB } = LOCAL_STORAGE_KEY;

// Initializer
export const initUserFromStorage = async (dispatch: any) => {
 
  const token = await localStorage.getItem(JWT_TOKEN);
  console.log(token);
  if (token === null || token === '') {
    return;
  }
  console.log('--load');
  let data = await UserService.getUserData();
  
  localStorage.setItem(USER_DATA, JSON.stringify(data.data.data));
  dispatch({ type: USER.SET_USER_DATA, payload: data.data.data });
  dispatch({ type: USER.USER_SUCCESS, payload: token });

  data = await MeetingService.getMeetings();
  const payload = data.data.data;
  dispatch({ type: MEETING.GET_MEETINGS_SUCCESS, payload });

  const result = await TransactionService.getTransactions();
  dispatch({ type: TRANSACTION.GET_TRANSACTIONS_SUCCESS, payload: result.data.data });
};

export const initPostJobFromStorage = async (dispatch: any) => {
  const post_job = await localStorage.getItem(POST_JOB);
  if(post_job === null && post_job === '') {
    return;
  }
  console.log('--posting job load');
  console.log(post_job);
  console.log(JSON.parse(post_job));
  dispatch({
    type: POSTJOB.SET_JOBINFO,
    payload: JSON.parse(post_job)
  });
}
