import { useDispatch, useSelector } from "react-redux";
import { MeetingService, axiosService, TransactionService } from "../../services";
import { MEETING, NOTI_TYPE } from "../types";
import { useProgress } from "./progress.hook";
import { useNotification } from './notification.hook';

export const useMeeting = () => {
  const dispatch = useDispatch();
  const { selectedMeetingInfo, selectedChannelIndex, contactSidebarOpen, meetings, channelUnread , loading, MeetingSelected } = useSelector(({ meeting }: any) => meeting);
  const { startProgress, stopProgress } = useProgress();
  const { setNewNotification } = useNotification();

  const getMeetings = async () => {
    try {
      startProgress();
      const data = await MeetingService.getMeetings();
      const payload = data.data.data;
      dispatch({ type: MEETING.GET_MEETINGS_SUCCESS, payload });
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      dispatch({
        type: MEETING.GET_MEETINGS_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  const changeContract = async (payload: any) => {
    try {
      startProgress();
      const data = await TransactionService.changeContract(payload);
      dispatch({ type: MEETING.CHANGE_CONTRACT, payload: { status: payload.status, data: data.data.data } });
      setNewNotification(NOTI_TYPE.SUCCESS, "Successfully!");
      stopProgress();
    } catch ({ response, message }) {
      stopProgress();
      if (response !== undefined)
        setNewNotification(NOTI_TYPE.WARNING, "something wrong. Please refresh");
      return false;
    }
  }

  const leaveMeeting = async () => {
    try {
      startProgress();
      console.log(selectedMeetingInfo);
      const data = await axiosService.post(`/meetings/${selectedMeetingInfo.id}/leave`, {'channel_id': selectedMeetingInfo?.channels[1]?.channel_id});      
      getMeetings();
      setSelectedChannelIndex(-1);
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();     
      return false;
    }
  }

  const endMeeting = async () => {
    try {
      startProgress();      
      const data = await axiosService.post(`/meetings/${selectedMeetingInfo.id}/end_meeting`);
      getMeetings();
      setSelectedChannelIndex(-1);
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();     
      return false;
    }
  }

  const onKick = async () => {
    try {
      startProgress();
      console.log(selectedMeetingInfo);
      await axiosService.post(`/meetings/${selectedMeetingInfo.id}/leave`, {'channel_id': selectedMeetingInfo?.channels[selectedChannelIndex]?.channel_id});      
      getMeetings();
      await getMeetingInfoById(selectedMeetingInfo.id);
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();     
      return false;
    }
  }

  const setSelectedChannelIndex = async (payload: any) => {
    try {
      dispatch({
        type: MEETING.SET_SELECTEDCHANNELINDEX,
        payload
      });

      if (payload !== -1)
        await MeetingService.setUnreadasRead(selectedMeetingInfo.channels[payload].channel_id);

      return true;
    } catch ({ response, message }) {
      return false;
    }
  };

  const setContactsSidebar = (payload: any) => {
    dispatch({
      type: MEETING.SET_CONTACTSIDEBAROPEN,
      payload,
    });
  }

  const getMeetingInfoById = async (id: any) => {
    try {
      startProgress();
      const data = await MeetingService.getMeetingInfoById(id);
      const payload = data.data.data;
      payload.id = id;
      dispatch({ type: MEETING.GET_MEETING_INFO_SUCCESS, payload });
      stopProgress();
      return true;
    } catch ({ response, message }) {
      stopProgress();
      dispatch({
        type: MEETING.GET_MEETING_INFO_FAILED,
        payload: "message"
      });
      return false;
    }
  }

  const sendMessage = async (payload: any) => {
    try {
      // dispatch({ type: MEETING.SEND_MESSAGE_SUCCESS_M, payload });
      await MeetingService.sendMessage(payload);
      return true;
    } catch ({ response, message }) {
      dispatch({
        type: MEETING.SEND_MESSAGE_FAILED_M,
        payload: "message"
      });
      return false;
    }
  }

  const updateAvailableStatus = async (payload: any) => {
    try {
      dispatch({ type: MEETING.UPDATE_AVAILABLE_STATUS, payload });
      // await MeetingService.updateAvailableStatus(payload);
      return true;
    } catch ({ response, message }) {
      dispatch({
        type: MEETING.UPDATE_AVAILABLE_STATUS_FAILED
      });
      return false;
    }
  }

  const loadNewMessage = (payload: any) => {
    dispatch({
      type: MEETING.LOAD_NEW_MESSAGE_MEETING,
      payload,
    });
    return true;
  }

  const loadNewMeeting = (payload: any) => {
    dispatch({
      type: MEETING.LOAD_NEW_MEETING,
      payload,
    });
    return true;
  }

  const endQA = async () => {
    try {
      await MeetingService.endQA(selectedMeetingInfo.job_id, selectedMeetingInfo.channels[selectedChannelIndex].channel_id);
      dispatch({ type: MEETING.SET_IS_FREE });
      return true;
    } catch ({ response, message }) {
    }
  }

  return {
    meetings,
    loading, 
    channelUnread,
    MeetingSelected,
    selectedMeetingInfo,
    selectedChannelIndex,
    contactSidebarOpen,
    getMeetingInfoById,
    getMeetings,
    loadNewMessage,
    loadNewMeeting,
    setSelectedChannelIndex,
    setContactsSidebar,
    sendMessage,
    updateAvailableStatus,
    endQA,
    leaveMeeting,
    changeContract,
    onKick,
    endMeeting
  };
};