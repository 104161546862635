import { useState } from 'react';

import MeetingThreads from './MeetingThreads';

export default function LeftSidebar() {

	return (
		<div className="px-3.5 pt-2.5 relative">
			<div className="flex flex-col justify-between h-full w-250">
				<div className="flex flex-col flex-1 space-y-3">
					<MeetingThreads />
				</div>
			</div>
		</div>
	);
}
