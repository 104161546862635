import React, { FunctionComponent } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoginPage from './login-page';
import SignupPage from './signup-page';
import CallbackPage from './callback-page';
import HomePage from './home-page';
import PostJobPage from './post-job-page';
import MatchFreelancersPage from './match-freelancers-page'
import SelectMeetingPage from './select-meeting-page'
import ProfilePage from '../auth-pages/profile-page'
import ForgotPasswordPage from './forgot-password-page'
import ResetPasswordPage from './reset-password-page'
import MeetingsPage from '../auth-pages/meetings-page'
import MeetingPage from '../auth-pages/meeting-page'
import PrivacyPage from './home-page/privacy';
import SupportPage from './home-page/support';


const UnAuthPages: FunctionComponent = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/auth/login" component={LoginPage} />
        <Route exact path="/auth/forgot_password" component={ForgotPasswordPage} />
        <Route exact path="/auth/reset_password/:id" component={ResetPasswordPage} />
        <Route exact path="/auth/callback" component={CallbackPage} />
        <Route exact path="/freelancer/signup" component={SignupPage} />
        <Route exact path="/client/signup" component={SignupPage} />
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/support" component={SupportPage} />
        <Route exact path="/client/post-job" component={PostJobPage} />
        <Route exact path="/client/match-freelancers" component={MatchFreelancersPage} />
        <Route exact path="/client/select-meeting" component={SelectMeetingPage} />
        <Route exact path="/profile/:id" component={ProfilePage} />
        <Redirect to="/home"></Redirect>
      </Switch>
    </React.Fragment>
  );
};

export default UnAuthPages;
