import './../../styles/styles.css'
import Avatar from '@material-ui/core/Avatar';
import { useUser, useTransaction } from "../../store/hooks";

const Invoice = (props: any) => {
    const { user } = useUser();
    const { selectedTransactionId, transactions, changeContract } = useTransaction();
    
    return (
        <>
            {user.user.user_role !== "freelancer" ?
                (<div className="flex flex-col items-end relative w-full mb-4" style={{ paddingRight: "45px" }}>
                    <Avatar
                        style={{ position: "absolute", top: '0', right: '0' }}
                        src={"../logo.png"}
                    ></Avatar>
                    <div className="w-1/2 border-solid border-gray-300 border-2 px-4 py-2">
                        {
                            props.item.chat_type == 'invoice_paid' ?
                                <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">Paid the Invoice</p>
                                : <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">Raised the invoice</p>
                        }
                        {props.item.invoice_items.length && props.item.invoice_items.length > 0 ?
                            props.item.invoice_items.map((item: any, index: any) => (
                                <p className="mb-1 text-gray-400 whitespace-pre-line" key={`invoice_item_${index}`}>
                                    Duration of the this work ({item.inv_dtl_date}){"\n"}{item.inv_dtl_total_hrs} Hours
                                </p>
                            )) :
                            null
                        }
                        <div className="flex mb-2 items-center">
                            <p className="text-blue-500 mr-1">Payment Processing Fee : </p>
                            <p className="text-gray-700 ml-1">${props.item.inv_fee}</p>
                        </div>
                        <div className="flex mb-2 items-center">
                            <p className="text-blue-500 mr-1">Total Price: </p>
                            <p className="text-gray-700 ml-1">${props.item.inv_total}</p>
                        </div>
                    </div>
                </div>) :
                (<div className="flex flex-col items-start relative w-full mb-4" style={{ paddingLeft: "45px" }}>
                    <Avatar
                        style={{ position: "absolute", top: '0', left: '0' }}
                        src={"../logo.png"}
                    ></Avatar>
                    <div className="w-1/2 border-solid border-gray-300 border-2 px-4 py-2">
                        {
                            props.item.chat_type == 'invoice_paid' ?
                                <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">The client Paid the Invoice</p>
                                : <p className="font-medium text-2xl text-center border-b-2 border-solid border-gray-200 mb-2">Raised the invoice to the client</p>
                        }
                        {props.item.invoice_items.length && props.item.invoice_items.length > 0 ?
                            props.item.invoice_items.map((item: any, index: any) => (
                                <p className="mb-1 text-gray-400 whitespace-pre-line" key={`invoice_item_${index}`}>
                                    Invoice Date: ({item.inv_dtl_date}) / total: {item.inv_dtl_total_hrs} Hours
                                </p>
                            )) :
                            null
                        }
                        <div className="flex mb-2 items-center">
                            <p className="text-blue-500 mr-1">Payment Processing Fee : </p>
                            <p className="text-gray-700 ml-1">${props.item.inv_fee}</p>
                        </div>
                        <div className="flex mb-2 items-center">
                            <p className="text-blue-500 mr-1">Total Price: </p>
                            <p className="text-gray-700 ml-1">${props.item.inv_total}</p>
                        </div>
                    </div>
                </div>
                )
            }
        </>
    );
};

export default Invoice;