import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePostJob, useUser } from "../../../store/hooks";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment-timezone";
import ReactTooltip from 'react-tooltip';
import Header from '../../../layouts/header'
import { convertLocalToUTC, convertLocalToEST, convertESTToLocal } from "../../../utils";

const useStyles = makeStyles(theme => ({
  freelancerCard: {
    fontWeight: 400,
    position: "relative",
    display: "flex",
    flexBasis: "100%",
    padding: "2rem",
    margin: "0px 4rem 2rem",
    overflow: "hidden",
    transition: "all .2s",
    boxShadow: "0 2px 3px 0 rgba(0,0,0,.1)",
    background: "#fff",
    border: "1px solid rgba(235,236,237,.5)",
    "&:hover": {
      border: "1px solid #edf0f8",
      boxShadow: "0 7px 15px 0 rgba(0, 0, 0, .1)",
    },
  },
  cropText: {
    height: "67.2px",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  titleColor: {
    color: "#183a9e",
  }
}));


const MatchFreelancersPage = (props: any) => {
  const history = useHistory();
  const { topFreelancers, getTopFreelancers, addJobInfo, setJobInfo, jobInfo } = usePostJob();
  const { user } = useUser();
  const classes = useStyles(props);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment().format("HH:mm"));
  const [showMores, setShowMores] = useState({});

  const goToBack = () => {
    history.push('/client/post-job?draft=true');
  }

  const handleSubmit = async () => {
    if (user.token !== '' && user.token !== null && user.token !== undefined) {
      await addJobInfo();
    }
    history.push('/client/select-meeting');
  }

  useEffect(() => {
    if(jobInfo.job_title) {
      getTopFreelancers(jobInfo);
      initDatetime();
    }
  }, [jobInfo.job_title])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const initDatetime = () => {
    let recommend_min = moment(convertESTToLocal(moment().format('YYYY-MM-DD') + ' 12:00')).format('YYYY-MM-DD HH:mm');
    let recommend_max = moment(convertESTToLocal(moment().format('YYYY-MM-DD') + ' 17:00')).format('YYYY-MM-DD HH:mm');
    if (moment().isBefore(recommend_min, 'hour')) {
      setDate(moment(recommend_min).format('YYYY-MM-DD'));
      setTime(moment(recommend_min).format('HH:mm'));
    } else if (moment().isAfter(recommend_max, 'hour')) {
      setDate(moment(recommend_min).add(1, 'day').format('YYYY-MM-DD'));
      setTime(moment(recommend_min).add(1, 'day').format('HH:mm'));
    } else {
      setDate(moment().format('YYYY-MM-DD'));
      setTime(moment().format('HH:mm'));
    }
  }

  const showProfilePage = (e: any, id: any) => {
    e.preventDefault();
    history.push('/profile/' + id);
  }

  const handleDateChange = (e: any) => {
    let new_date = e.target.value;
    let dt = new_date + " " + time;
    if (moment(dt).isSameOrAfter(moment().add(1, 'hours'))) {
      setDate(new_date);
    } else {
      alert("Please select the datetime after " + moment().add(1, 'hours').format('YYYY-MM-DD hh:mm A'));
    }
  }

  const handleTimeChange = (e: any) => {
    let new_time = e.target.value;
    let dt = date + " " + new_time;
    if (moment(dt).isSameOrAfter(moment().add(1, 'hours'))) {
      setTime(new_time);
    } else {
      alert("Please select the datetime after " + moment().add(1, 'hours').format('YYYY-MM-DD hh:mm A'));
    }
  }

  useEffect(() => {
    if(jobInfo.job_title) {
      let dt = date + " " + time;
      setJobInfo({ ...jobInfo, job_schedule: convertLocalToUTC(dt) });
    }
  }, [time, date, jobInfo.job_title])

  const handleShowMoreBtn = (id: any) => {
    let new_showMores = { ...showMores }
    new_showMores[id] = new_showMores[id] ? false : true;
    setShowMores(new_showMores);
  }

  return (
    <>
      <div className="h-full overflow-auto pb-4">
        <div className="hero-area h-80 pt-16">
          <div className="text-center ">
            <p className={classes.titleColor + " text-5xl"}>We matched the top 5 freelancers here.</p>
            <p className="text-lg my-6 text-gray-700">You can setup the meeting time with them now.</p>
            <div className="flex justify-center items-center">
              <button onClick={goToBack} className="secondary-btn mt-4 py-3 px-8 font-medium text-xl" style={{ height: '100%' }}>Back</button>&nbsp;
              <button onClick={handleSubmit} className="bg-green-400 text-xl py-3 px-8 rounded text-white font-medium mt-4 hover:bg-green-600 focus:outline-none">
                Schedule the meeting
              </button>
            </div>
          </div>
        </div>
        <div className="container py-10 mx-auto flex flex-col">
          {topFreelancers.map((freelancer: any, i: any) =>
            <div className={classes.freelancerCard} key={i}>
              <div className="w-1/5 px-4">
                <div className="group relative">
                  <div className="image_box">
                    <a className="w-full mx-auto h-auto left-0 top-0" onClick={e => showProfilePage(e, freelancer.user_id)} href="void(0)">
                      {freelancer.avatar === null || freelancer.avatar === undefined || freelancer.avatar === "" ?
                        <img className="w-full h-auto" src="/assets/imgs/avatar.png" alt=""></img> :
                        <img className="w-full h-auto" src={process.env.REACT_APP_BASE_URL + freelancer.avatar} alt=""></img>}
                    </a>
                  </div>
                  <div onClick={e => showProfilePage(e, freelancer.user_id)} className='group-hover:opacity-80 opacity-0 absolute top-0 left-0 transform w-full h-full bg-blue-500 flex justify-center items-center text-white font-medium cursor-pointer'>
                    View Full Profile
                  </div>
                </div>

                <button onClick={e => showProfilePage(e, freelancer.user_id)} className="primary-btn w-full mt-4 py-4">View {freelancer.first_name}</button>
              </div>
              <div className="w-4/5 px-4">
                <p className="text-blue-700 text-lg font-bold">{freelancer.full_name}</p>
                <p className="text-blue-700 mb-8">English: {freelancer.english_level}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Timezone: {freelancer.timezone}</p>
                <p
                  style={{ fontSize: '16px', lineHeight: 1.4 }}
                  className={(!showMores[freelancer.user_id] ? classes.cropText : '')}>
                  {freelancer.description}
                </p>
                {
                  freelancer.description && freelancer.description.length > 280 &&
                  <button onClick={e => handleShowMoreBtn(freelancer.user_id)} className="text-blue-700 cursor-pointer">{!showMores[freelancer.user_id] ? 'Show More...' : 'Show Less...'}</button>
                }

                <div className="flex justify-between px-8">
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f917;</p>
                    <p className="font-bold">Quality</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.quality_flag} Quality badges`}>{freelancer?.overall_scores?.quality_flag}</p>
                  </div>
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f604;</p>
                    <p className="font-bold">Speed</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.speed_flag} Speed badges`}>{freelancer?.overall_scores?.speed_flag}</p>
                  </div>
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f60D;</p>
                    <p className="font-bold">Deadline</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.deadline_flag} Deadline badges`}>{freelancer?.overall_scores?.deadline_flag}</p>
                  </div>
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f603;</p>
                    <p className="font-bold">Community</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.community_flag} Community badges`}>{freelancer?.overall_scores?.community_flag}</p>
                  </div>
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f970;</p>
                    <p className="font-bold">Design</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.design_flag} Design badges`}>{freelancer?.overall_scores?.design_flag}</p>
                  </div>
                  <div className="items-center text-center w-20">
                    <p className="text-4xl mb-4">&#x1f621;</p>
                    <p className="font-bold">Bad</p>
                    <p data-tip={`Earned ${freelancer?.overall_scores?.bad_flag} Bad badges`}>{freelancer?.overall_scores?.bad_flag}</p>
                  </div>
                </div>
              </div>

            </div>
          )}
          <div className="pl-16 mt-2">
            <p className="text-black">Tip:It is better to setup meeting schedule around 24 hours.</p>
            <p className="text-black">You can group chat with them at the same time to select prefer developer.</p>
            <p className="text-black">The average meeting time for 5 developers are <b><u>EST 12PM ~ EST 5PM</u></b></p>
          </div>
          <div className="px-16 mt-5 flex flex-col sm:flex-row">
            <div className="sm:w-1/5">
              <input
                value={date}
                onChange={(e: any) => handleDateChange(e)}
                type="date"
                min={moment().format("YYYY-MM-DD")}
                placeholder="Meeting Date"
                className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2" />
            </div>
            <div className="sm:w-1/5">
              <input
                value={time}
                onChange={(e: any) => handleTimeChange(e)}
                type="time"
                step={600}
                placeholder="Meeting Time"
                className="block ml-3 w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring my-2" />
            </div>
            <div className="sm:w-1/5">
            </div>
            <div className="sm:w-2/5 flex justify-end">
              <button onClick={goToBack} className="ml-3 secondary-btn py-2 px-4 font-medium text-xl tracking-wide" style={{ height: '100%' }}>Back</button>&nbsp;
              <button onClick={handleSubmit} className="px-4 py-2 font-medium text-xl tracking-wide text-white transition-colors duration-200 transform bg-green-400 rounded hover:bg-green-600 focus:outline-none">
                Schedule the meeting
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </>
  );
}

export default MatchFreelancersPage;
