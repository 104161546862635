import { useMemo } from 'react';

interface MeetingThreadProps {
	id: number;
	title: string;
	status: number;
	note?: string;	
	unread?: boolean;	
	onClick: (id: number) => void;
}

export default function MeetingThread({
	id,
	title,
	status,
	note,		
	unread,
	onClick,
}: MeetingThreadProps) {
	const nameClassName = useMemo(() => {
		if (status == 1) return 'text-dp-blue-very-dark font-semibold';
		if (status == 0) return 'text-dp-gray-very-dark font-semibold';
		if (unread) return 'text-dp-blue-very-dark font-semibold';
		return 'text-dp-blue-very-dark font-regular font-semibold';
	}, [status, unread]);

	const messageClassName = useMemo(() => {
		if (status == 1) return 'text-dp-blue-very-dark text-xs';
		if (status == 0) return 'text-dp-gray-very-dark text-xs';
		if (unread) return 'text-dp-blue-dark font-semibold text-xs';
		return 'text-dp-gray-very-dark text-xs';
	}, [status, unread]);

	return (
		<button
			onClick={() => onClick(id)}
			className={`${
				status == 1 ? 'bg-dp-blue-light' : 'bg-white'
			} transition w-full px-3 py-2 focus:outline-none rounded-md flex-shrink-0`}
		>
			<div className="flex justify-between items-center w-full mb-1">
				<span className={`${nameClassName} text-sm truncate`}>{title}</span>
				<div className="ml-1 flex flex-shrink-0 items-center">
					<span style={{ fontSize: 12 }} className="text-dp-gray-very-dark leading-none">
						&nbsp;
					</span>
					{unread && <div className="ml-1 h-3 w-3 rounded-full bg-dp-blue-dark"></div>}					
				</div>
			</div>
			<div className="text-left truncate">
				<p className={`${messageClassName} text-xs leading-tight`}>{note}</p>
			</div>
		</button>
	);
}
