export const MEETING = {
  GET_MEETING_INFO: "GET_MEETING_INFO",
  GET_MEETING_INFO_SUCCESS: "GET_MEETING_INFO_SUCCESS",
  GET_MEETING_INFO_FAILED: "GET_HISTORY_FAILED",
  GET_MEETINGS: 'GET_MEETINGS',
  GET_MEETINGS_SUCCESS: 'GET_MEETINGS_SUCCESS',
  GET_MEETINGS_FAILED: 'GET_MEETINGS_FAILED',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS_M: 'SEND_MESSAGE_SUCCESS_M',
  SEND_MESSAGE_FAILED_M: 'SEND_MESSAGE_FAILED_M',
  SET_MOBILECHATSIDEBAROPEN: 'SET_MOBILECHATSIDEBAROPEN',
  SET_CONTACTSIDEBAROPEN: 'SET_CONTACTSIDEBAROPEN',
  SET_SELECTEDCHANNELINDEX: 'SET_SELECTEDCHANNELINDEX',
  LOAD_NEW_MESSAGE_MEETING: 'LOAD_NEW_MESSAGE_MEETING',
  LOAD_NEW_MEETING: 'LOAD_NEW_MEETING',
  SET_IS_FREE: 'SET_IS_FREE',
  CHANGE_CONTRACT: 'CHANGE_CONTRACT',
  UPDATE_AVAILABLE_STATUS: 'UPDATE_AVAILABLE_STATUS',
  UPDATE_AVAILABLE_STATUS_FAILED: 'UPDATE_AVAILABLE_STATUS_FAILED',
}