import { Hashtags } from '../../models';
import { CONSTS } from '../types';

const initialState: Hashtags = {
  hashtags: [],
  error: '',
};

export default function constsReducer(state = initialState, { type, payload }: any) {
  switch (type) {
    case CONSTS.GET_HASHTAGS_SUCCESS:
      return {
        ...state,
        hashtags: payload,
      };
    case CONSTS.GET_HASHTAGS_FAILED:
      return {
        ...state,
        error: payload
      };
    case CONSTS.ADD_HASHTAG_SUCCESS:
      return {
        ...state,
        hashtags: [...state.hashtags, payload],
      };
    case CONSTS.ADD_HASHTAG_FAILED:
      return {
        ...state,
        error: payload
      };
    case CONSTS.DELETE_HASHTAG_SUCCESS:
      let hashtags = [...state.hashtags];
      let index  = hashtags.findIndex((hashtag) => hashtag.hashtag_id == payload);
      hashtags.splice(index, 1);
      return {
        ...state,
        hashtags: hashtags,
      };
    case CONSTS.DELETE_HASHTAG_FAILED:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}
