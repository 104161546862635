import './../../styles/styles.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const PortfolioCard = (props: any) => {
  const editPortfolio = () => {
    props.editPortfolio(props.index);
  }
  const deletePortfolio = () => {

    confirmAlert({
      title: 'Do you want to remove the portfolio?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => props.deletePortfolio(props.portfolio.por_id)
        },
        {
          label: 'No',
          onClick: () => { return false; }
        }
      ]
    });

  }
  const viewPortfolio = (e: any) => {
    e.preventDefault();
    props.viewPortfolio(props.index);
  }
  return (
    <>
      <div className="p-2">
        <div className={`group h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden ${props.editable ? 'hover:bg-black hover:opacity-80' : ''} cursor-pointer relative`}>
          {props.editable && (<><div onClick={editPortfolio} className='group-hover:opacity-100 opacity-0 edit-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -ml-4'>
            <img src="/icons/edit-icon.svg" alt="edit"></img>
          </div>
            <div onClick={deletePortfolio} className='group-hover:opacity-100 opacity-0 remove-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ml-4'>
              <img src="/icons/delete-icon.svg" alt="delete"></img>
            </div></>)}
          <div className="p-6 w-full h-full">
            <h1 className="title-font portfolio-card-header font-medium text-teal-600 mb-3">{props.portfolio.por_title}</h1>
            <p className="leading-relaxed mb-3 portfolio-card-content">{props.portfolio.por_desc}</p>
            <div className="flex items-center flex-wrap justify-end">
              <a onClick={viewPortfolio} className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 hover:underline" href="void(0)">View Details
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioCard;